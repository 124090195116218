<template>
  <AdminHeader />
  <div class="profesionales-container">
    <div class="title-section-list">Lista de Profesionales</div>
    <div class="search-container">
      <select v-model="searchBy" class="search-select">
        <option value="nombre">Nombre</option>
        <option value="apellido">Apellido</option>
        <option value="dni">DNI</option>
      </select>
      <input
        v-model="searchTerm"
        type="text"
        class="search-input"
        placeholder="Ingrese el valor..."
      />
      <button @click="filterProfesionales" class="button">Buscar</button>
    </div>
    <table class="profesionales-table">
      <thead>
        <tr>
          <th>DNI</th>
          <th>Nombre</th>
          <th>Apellido</th>
          <th>Especialidad</th>
          <th>Cantidad Pacientes</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="profesional in filteredProfesionales"
          :key="profesional.guid"
          @click="verProfesional(profesional.guid)"
          class="clickable-row"
        >
          <td>{{ profesional.dni }}</td>
          <td>{{ profesional.nombre }}</td>
          <td>{{ profesional.apellido }}</td>
          <td>{{ profesional.especialidad }}</td>
          <td>{{ profesional.cantidad_pacientes }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import AdminHeader from '@/views/AdminViews/AdminComponents/AdminHeader.vue';

const router = useRouter();

// Estados del componente
const profesionales = ref([]);
const searchBy = ref('nombre');
const searchTerm = ref('');

// Fetch de profesionales desde la API
const fetchProfesionales = async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/profesionales-basic-info`);
    const data = await response.json();
    profesionales.value = data; // Guarda los datos en el estado
  } catch (error) {
    console.error("Error al obtener los profesionales:", error);
  }
};

// Filtrado de profesionales basado en el término de búsqueda
const filteredProfesionales = computed(() => {
  if (!searchTerm.value.trim()) {
    return profesionales.value; // Si no hay término de búsqueda, muestra todos los profesionales
  }

  return profesionales.value.filter((profesional) => {
    const value = profesional[searchBy.value]?.toLowerCase() || '';
    return value.includes(searchTerm.value.toLowerCase());
  });
});

// Método para navegar al detalle del profesional
const verProfesional = (guid) => {
  router.push(`/profesional/${guid}`);
};

// Hook para cargar los datos al montar el componente
onMounted(() => {
  fetchProfesionales();
});
</script>


<style scoped>
.profesionales-container {
padding: 20px;
}

.clickable-row {
cursor: pointer;
}
</style>
