<template>
  <div class="editar-paciente-modal-overlay" v-if="mostrarModal">
    <div class="modal-container">
      <div class="modal-header">
        <h2>Editar Paciente</h2>
        <button class="close-button" @click="closeModal">X</button>
      </div>

      <div class="form-container">
        <form @submit.prevent="guardarCambios" class="formulario">
          <label class="form-label">Nombre: <input v-model="pacienteEditable.nombre" type="text" required /></label>
          <label class="form-label">Apellido: <input v-model="pacienteEditable.apellido" type="text" required /></label>
          <label class="form-label">DNI: <input v-model="pacienteEditable.dni" type="text" required /></label>
          <label class="form-label">Fecha de Nacimiento: <input v-model="pacienteEditable.fecha_nacimiento" type="date" required /></label>

          <label class="form-label">Fecha ingreso a Vínculos: <input v-model="pacienteEditable.ano_ingreso_vinculos" type="date" required /></label>

          <div class="domicilio-fila">
            <label class="form-label">Localidad: <input v-model="pacienteEditable.domicilio.localidad" type="text" required /></label>
            <label class="form-label">Calle: <input v-model="pacienteEditable.domicilio.calle" type="text" /></label>
            <label class="form-label">Altura: <input v-model="pacienteEditable.domicilio.altura" type="number" /></label>
            <label class="form-label">Piso: <input v-model="pacienteEditable.domicilio.piso" type="text" /></label>
          </div>

          <label class="form-label">Observaciones: <input v-model="pacienteEditable.domicilio.observaciones" type="text" /></label>

          <label class="form-label">Teléfono Urgencia: <input v-model="pacienteEditable.telefono_caso_urgencia" type="text" required /></label>
          
          <label class="form-label">Madre: <input v-model="pacienteEditable.madre" type="text" /></label>
          <label class="form-label">Padre: <input v-model="pacienteEditable.padre" type="text" /></label>
          <label class="form-label">Teléfono Madre: <input v-model="pacienteEditable.nro_tel_madre" type="text" /></label>
          <label class="form-label">Teléfono Padre: <input v-model="pacienteEditable.nro_tel_padre" type="text" /></label>
          <label class="form-label">Diagnóstico: <input v-model="pacienteEditable.diagnostico" type="text" /></label>
          <label class="form-label">Médico Neurólogo: <input v-model="pacienteEditable.medico_neurologo" type="text" /></label>
          <label class="form-label">Psiquiatra: <input v-model="pacienteEditable.psiquiatra" type="text" /></label>
          <label class="form-label">Pediatra: <input v-model="pacienteEditable.pediatra" type="text" /></label>
          <label class="form-label">Medicación: <input v-model="pacienteEditable.medicacion" type="text" /></label>
          <label class="form-label">Alérgico: <input v-model="pacienteEditable.alergico" type="text" /></label>

          <div class="os-fila">
  <label class="form-label">Obra Social: {{ pacienteEditable.nombre_os }}</label>
  <button v-if="!cambiarObraSocialVisible" class="button-2" type="button" @click="mostrarCambiarObraSocial">
    Cambiar Obra Social
  </button>
</div>

<div v-if="cambiarObraSocialVisible" class="cambiar-obra-social-container">
  <label class="form-label">Nueva Obra Social:
    <select v-model="nuevaObraSocial">
      <option v-for="obra in obrasSociales" :key="obra.id_obra_social" :value="obra.id_obra_social">
        {{ obra.nombre_os }}
      </option>
    </select>
  </label>
  <button type="button" @click="guardarNuevaObraSocial">Listo</button>
  <button type="button" @click="ocultarCambiarObraSocial">Cancelar</button>
</div>


          <label class="form-label">Vencimiento de CUD: <input v-model="pacienteEditable.vencimiento_CUD" type="date" /></label>

          <!-- Añade esto antes de los botones de Cancelar y Guardar -->
<div v-if="errores.length" class="errores">
  <ul>
    <li v-for="(error, index) in errores" :key="index">
      {{ error }}
    </li>
  </ul>
</div>

          <div class="modal-footer">
            <button type="button" @click="closeModal" class="button">Cancelar</button>
            <button type="submit" class="button">Guardar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted } from 'vue';

const props = defineProps({
  mostrarModal: Boolean,
  paciente: Object,
});

const emit = defineEmits(['close']);

const closeModal = () => {
  emit('close');
};

const extraerFecha = (fechaISO) => {
  if (!fechaISO) return '';
  return fechaISO.substring(0, 10);
};

const pacienteEditable = ref({
  guid: props.paciente?.guid,
  nombre: props.paciente?.nombre || '',
  apellido: props.paciente?.apellido || '',
  dni: props.paciente?.dni || '',
  fecha_nacimiento: extraerFecha(props.paciente?.fecha_nacimiento),
  telefono_caso_urgencia: props.paciente?.telefono_caso_urgencia || '',
  nombre_os: props.paciente?.nombre_os || '',
  obra_social: props.paciente?.id_obra_social || '', // Asegura que el ID esté presente
  id_obra_social: null, // Se asignará en onMounted
  vencimiento_CUD: extraerFecha(props.paciente?.vencimiento_CUD),
  ano_ingreso_vinculos: extraerFecha(props.paciente?.ano_ingreso_vinculos),
  madre: props.paciente?.madre || '',
  padre: props.paciente?.padre || '',
  nro_tel_madre: props.paciente?.nro_tel_madre || '',
  nro_tel_padre: props.paciente?.nro_tel_padre || '',
  diagnostico: props.paciente?.diagnostico || '',
  medico_neurologo: props.paciente?.medico_neurologo || '',
  psiquiatra: props.paciente?.psiquiatra || '',
  pediatra: props.paciente?.pediatra || '',
  medicacion: props.paciente?.medicacion || '',
  alergico: props.paciente?.alergico || '',
  domicilio: {
    localidad: props.paciente?.localidad || 'Santa Fe',
    calle: props.paciente?.calle || '',
    altura: props.paciente?.altura || '',
    piso: props.paciente?.piso || '',
    observaciones: props.paciente?.observaciones || '',
  },
});
console.log("datos en modaleditarpaciente" , pacienteEditable)
const errores = ref([]);

const obrasSociales = ref([]);
const cambiarObraSocialVisible = ref(false);
const nuevaObraSocial = ref('');

const cargarObrasSociales = async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/os`);
    const data = await response.json();
    obrasSociales.value = data;
    console.log("OBRAS SOCIALES FETCH", JSON.stringify(data));
    
    // Buscar y establecer el id_obra_social correspondiente
    const obraSocial = data.find(os => os.nombre_os === pacienteEditable.value.nombre_os);
    if (obraSocial) {
      pacienteEditable.value.obra_social = obraSocial.obra_social;
      console.log("ID OS: ",pacienteEditable.value.obra_social)
    }
  } catch (error) {
    console.error('Error al cargar las obras sociales:', error);
  }
};


const guardarCambios = async () => {
  // Limpiar errores previos
  errores.value = [];

  if (!props.paciente?.guid) {
    errores.value.push("Error: Falta el GUID del paciente.");
    return;
  }

  // Hacer una copia de pacienteEditable.value
  const pacienteData = { ...pacienteEditable.value };

  // Eliminar el campo nombre_os
  delete pacienteData.nombre_os;
  delete pacienteData.guid;
  delete pacienteData.id_obra_social;
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/actualizar-paciente/${props.paciente?.guid}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(pacienteData),
    });

    const data = await response.json();

    if (!response.ok) {
      if (data.detalles && Array.isArray(data.detalles)) {
        // Convertir los errores en un array de mensajes entendibles
        errores.value = data.detalles.map((error) => error.message);
      } else {
        errores.value.push(data.message || "Error desconocido al procesar la solicitud.");
      }
      return;
    }

    alert("Paciente actualizado con éxito");
    closeModal(); 
    window.location.reload();
  } catch (error) {
    console.error("Error en la actualización:", error);
    errores.value.push("No se pudo conectar con el servidor: " + error.message);
  }
};

const mostrarCambiarObraSocial = () => {
  cambiarObraSocialVisible.value = true;

};

const ocultarCambiarObraSocial = () => {
  cambiarObraSocialVisible.value = false;
};

const guardarNuevaObraSocial = () => {
  const nuevaOS = obrasSociales.value.find(os => os.id_obra_social === nuevaObraSocial.value);

  if (nuevaOS) {
    pacienteEditable.value.obra_social = nuevaOS.id_obra_social; // Asigna el nuevo ID
    pacienteEditable.value.nombre_os = nuevaOS.nombre_os; // Actualiza el nombre mostrado
  }

  console.log("Obra Social actualizada:", pacienteEditable.value.obra_social, pacienteEditable.value.nombre_os);

  ocultarCambiarObraSocial();
};



onMounted(async () => {
  await cargarObrasSociales(); // Espera a que se carguen las obras sociales

  // Buscar el ID de la obra social correspondiente al nombre
  const obraSocialEncontrada = obrasSociales.value.find(os => os.nombre_os === props.paciente?.nombre_os);

  if (obraSocialEncontrada) {
    pacienteEditable.value.obra_social = obraSocialEncontrada.id_obra_social;
  }

  console.log("Obra Social asignada:", pacienteEditable.value.obra_social);
});

</script>

<style scoped>
.errores {
  background: #ffdddd;
  color: #d8000c;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}
.errores ul {
  margin: 0;
  padding-left: 20px;
}
.cambiar-obra-social-container {
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 10px;
}

.os-fila {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}
 
  .editar-paciente-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 90vw; /* Limita el ancho máximo al 90% del ancho de la ventana */
  max-height: 90vh; /* Limita la altura máxima al 90% de la altura de la ventana */
  overflow: auto; /* Permite el desplazamiento si el contenido excede el tamaño del contenedor */
}

.modal-header,
.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
 
}

.close-button {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

  </style>