import { createRouter, createWebHistory } from 'vue-router';
import { ref } from 'vue';

import Home from '@/views/Home.vue';


import Admin from '@/views/AdminViews/AdminView.vue';

import PacientesPageADM  from '@/views/AdminViews/PacientesPageADM.vue'
import PacientesPageAddADM from '@/views/AdminViews/PacientesPageAddADM.vue' 

import ProfesionalesPageADM  from '@/views/AdminViews/ProfesionalesPageADM.vue'
import ProfesionalesPageAddADM from '@/views/AdminViews/ProfresionalesPageAddADM.vue'

import ObrasSocialesPageADM  from '@/views/AdminViews/ObrasSocialesPageADM.vue'
import ObrasSocialesPageAddADM  from '@/views/AdminViews/ObrasSocialesPageAddADM.vue'

import ConsultoriosPageADM from '@/views/AdminViews/ConsultoriosPageADM.vue'
import ConsultoriosPageAddADM from '@/views/AdminViews/ConsultoriosPageAddADM.vue'

import TurnosPageADM from '@/views/AdminViews/TurnosPageADM.vue';

import  VistaGeneralDeTurnos  from "@/views/AdminViews/VistaGeneralDeTurnos.vue";


import PacienteDetalle from '@/views/AdminViews/PacienteDetalles.vue'
import  ProfesionalDetalle from '@/views/AdminViews/ProfesionalDetalles.vue'
import LoginView from '@/views/LoginView.vue';
import LogoutView from '@/views/LogoutView.vue';



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home // Esta será la página principal
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView // Página de login
  },
  {
    path: '/logout',
    name: 'LogoutView',
    component: LogoutView,
    meta: { requiresAuth: true } 
  },


  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: { requiresAuth: true }
  },


  {
    path: '/pacientes',
    name: 'PacientesPageADM',
    component: PacientesPageADM,
    meta: { requiresAuth: true }
  },
  {
    path: '/agregar-paciente',
    name: 'PacientesPageAddADM',
    component: PacientesPageAddADM,
    meta: { requiresAuth: true }
  },
  {
    path: '/profesionales',
    name: 'ProfesionalesPageADM',
    component: ProfesionalesPageADM,
    meta: { requiresAuth: true }
  },
  {
    path: '/agregar-profesional',
    name: 'ProfesionalesPageAddADM',
    component: ProfesionalesPageAddADM,
    meta: { requiresAuth: true }
  }
  ,
  {path: '/obras-sociales',
    name: 'ObrasSocialesPageADM',
    component: ObrasSocialesPageADM,
    meta: { requiresAuth: true }
    },


    {path: '/agregar-obras-sociales',
      name: 'ObrasSocialesPageAddADM',
      component: ObrasSocialesPageAddADM,
      meta: { requiresAuth: true }
      },

      {path: '/consultorios',
        name: 'ConsultoriosPageADM',
        component: ConsultoriosPageADM,
        meta: { requiresAuth: true }
        },

        {path: '/agregar-consultorios',
          name: 'ConsultoriosPageAddADM',
          component: ConsultoriosPageAddADM,
          meta: { requiresAuth: true }
          },
          {path: '/turnos',
            name: ' TurnosPageADM',
            component:  TurnosPageADM,
            meta: { requiresAuth: true }
            },

            {
              path: '/pacientes/:guid',
              name: 'PacienteDetalle',
              component: PacienteDetalle,
              meta: { requiresAuth: true }
            } ,


            {
              path: '/profesional/:guid',
              name: 'ProfesionalDetalles',
              component: ProfesionalDetalle,
              meta: { requiresAuth: true },
              props: true
            },

            {
              path: '/turnos/todos',
              name: 'VistaGeneralDeTurnos',
              component: VistaGeneralDeTurnos,
              meta: { requiresAuth: true }
            }
    
  
];


const isAuthenticated = ref(false)
const userRole = ref(null)

const router = createRouter({
  history: createWebHistory(),
  routes
});


// Función para verificar autenticación con el backend
async function checkAuthStatus() {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/auth-token/verify`, {
      credentials: 'include'
    });
    
    if (!response.ok) {
      throw new Error('Error al verificar autenticación');
    }
    
    const data = await response.json();
    isAuthenticated.value = data.isAuthenticated;
    userRole.value = data.user?.role;
    return data;
  } catch (error) {
    console.error('Error de autenticación:', error);
    isAuthenticated.value = false;
    userRole.value = null;
    return { isAuthenticated: false };
  }
}

// Guardián de navegación
router.beforeEach(async (to, from, next) => {
  // Si la ruta requiere autenticación
  if (to.meta.requiresAuth) {
    // Verificar estado de autenticación
    const authStatus = await checkAuthStatus();
    
    if (!authStatus.isAuthenticated) {
      // Redirigir a login si no está autenticado
      next({ 
        path: '/login', 
        query: { redirect: to.fullPath } // Opcional: guarda la ruta a la que intentaba acceder
      });
    } 
    // Verificar roles si es necesario
    else if (to.meta.roles && !to.meta.roles.includes(authStatus.role)) {
      // No tiene el rol requerido
      next('/unauthorized');
    } 
    else {
      // Está autenticado y tiene los permisos necesarios
      next();
    }
  } else {
    // La ruta no requiere autenticación
    // Opcionalmente, puedes redirigir usuarios ya logueados desde la página de login
    if (to.path === '/login') {
      const authStatus = await checkAuthStatus();
      if (authStatus.isAuthenticated) {
        // Si el usuario ya está autenticado y trata de ir a login, redirigirlo al dashboard
        next('/admin');
        return;
      }
    }
    next();
  }
});

export default router;
