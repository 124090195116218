<template>
  <AdminHeader />
  <div class="turnos-page-adm">
    <!-- Título por encima -->
    <h1>Gestión de Turnos</h1>

    <!-- Contenedor grande para las dos columnas -->
    <div class="columns-container">
      <!-- Columna derecha: Tabla de turnos -->
      <div class="table-section">
        <h2>Lista de Turnos</h2>

        <!-- Aplicar la clase "tabla-container" y "turnos-table" -->
        <div class="tabla-container">
          <table class="turnos-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Paciente</th>
                <th>Profesional</th>
                <th>Fecha</th>
                <th>Hora</th>
                <th>Consultorio</th>
                <th>Observaciones</th>
              
              </tr>
            </thead>
            <tbody>
              <tr v-for="turno in turnosFiltrados" :key="turno.id_turno || turno.id_recurrente">
                <td>
                  <!-- Mostrar ID del turno recurrente o normal -->
                  <span v-if="turno.esRecurrente" class="recurrente">R-{{ turno.id_recurrente }}</span>
                  <span v-else class="normal">N-{{ turno.id_turno }}</span>
                </td>
                <td>
    <router-link
        v-if="turno.guid_paciente"
        :to="`/pacientes/${turno.guid_paciente}`"
        class="custom-link"
    >
        {{ turno.nombre_paciente }} {{ turno.apellido_paciente }}
    </router-link>
    <span v-else>Sin paciente</span>
</td>
<td>
    <router-link
        v-if="turno.guid_profesional"
        :to="`/profesional/${turno.guid_profesional}`"
        class="custom-link"
    >
        {{ turno.nombre_profesional }} {{ turno.apellido_profesional }}
    </router-link>
    <span v-else>Sin profesional</span>
</td>

                <td>{{ formatDate(turno.fecha_turno) }}</td>
                <td>{{ formatTime(turno.fecha_turno) }}</td>
                <td>{{ turno.id_consultorio }}</td>
                <td>{{ turno.observaciones }}</td>
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Columna izquierda: Calendario -->
      <div class="calendar-section">
        <h2>Seleccionar Fecha</h2>
        <div class="seleccined-date-title">{{ dia_seleccionado }}</div>
        <v-calendar
          class="custom-calendar"
          :attributes="calendarAttributes"
          @dayclick="onDayClick"
          @update:from-page="onPageChange"
          theme-styles="{
            today: { color: '#ff0000', fontWeight: 'bold' },
            selected: { backgroundColor: '#007bff', color: '#fff' },
          }"
        />
        <div class="buttoms-calendar">
          <button class="button" @click="openModal">Agregar Nuevo Turno</button>
          <button class="button" @click="$router.push('/turnos/todos')">Ver Todos los Turnos</button>
        </div>
      </div>
    </div>
  </div>
  <TurnosADDcomponent v-if="isModalOpen" :isModalOpen="isModalOpen" @close="closeModal" />
</template>

  <script>
  import AdminHeader from '@/views/AdminViews/AdminComponents/AdminHeader.vue'
  import { ref, computed } from 'vue';
  import TurnosADDcomponent from './AdminComponents/TurnosADDcomponent.vue';

  export default {
    name: 'TurnosPageADM',
    components: { AdminHeader, TurnosADDcomponent },
    

    setup() {
      // Estado reactivo para la fecha seleccionada
      const dia_seleccionado = ref(new Date().toLocaleDateString('es-ES', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }).toUpperCase());

      // Estado reactivo para almacenar los turnos obtenidos de la API
      const turnos = ref([]);

      // Función para formatear la fecha (ej: "2025-02-03T16:00:00.000Z" -> "03/02/2025")
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('es-ES');
      };

      // Función para formatear la hora (ej: "2025-02-03T16:00:00.000Z" -> "16:00")
      const formatTime = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });
      };

      // Función para obtener los turnos de la API según la fecha seleccionada
      const fetchTurnos = async (fecha) => {
        try {
          const response = await fetch(`${process.env.VUE_APP_API_URL}/turnos/get-turnos-dia/${fecha}`);
          if (!response.ok) {
            throw new Error('Error al obtener los turnos');
          }
          const data = await response.json();
          turnos.value = data; // Almacena los turnos obtenidos
        } catch (error) {
          console.error('Error:', error);
        }
      };

      // Función para manejar clic en un día del calendario
      const onDayClick = (day) => {
        const fechaSeleccionada = new Date(day.date).toISOString().split('T')[0]; // Formato YYYY-MM-DD
        dia_seleccionado.value = new Date(day.date).toLocaleDateString('es-ES', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }).toUpperCase();
        fetchTurnos(fechaSeleccionada); // Llama a la API con la fecha seleccionada
      };

      // Atributos para el calendario (resaltar fechas, etc.)
      const calendarAttributes = ref([
        {
          key: 'today',
          highlight: {
            color: 'blue',
            fillMode: 'light',
          },
          dates: new Date(), // Resalta el día actual
        },
      ]);

      // Computed para filtrar los turnos según la fecha seleccionada (opcional)
      const turnosFiltrados = computed(() => {
        return turnos.value; // Aquí puedes agregar lógica adicional si necesitas filtrar más
      });









    

      const isModalOpen = ref(false);

      const openModal = () => {
      

            isModalOpen.value = true;
  };

  // Método para cerrar el modal
  const closeModal = () => {
    isModalOpen.value = false;
  };




      return {
        turnosFiltrados,
        calendarAttributes,
        onDayClick,
        dia_seleccionado,
        formatDate,
        formatTime,
        closeModal,
        openModal,
        isModalOpen
      
      };
    },
  };
  </script>
  
  <style scoped>
 /* Estilos generales para la tabla */
/* Estilos generales para la tabla */
/* Estilos generales */
.turnos-page-adm {
  overflow-x: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra el contenido */
  text-align: center;
}

/* Contenedor de columnas */
.columns-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; /* Centra horizontalmente */
  width: 100%;
}

/* Secciones de tabla y calendario */
.table-section,
.calendar-section {
  flex: 1;
  min-width: 300px;
  width: 100%;
  max-width: 900px; /* Opcional, para que no sean demasiado anchos en pantallas grandes */
}

/* Tabla responsive */
.tabla-container {
  overflow-x: auto;
  max-width: 100%;
  margin: 0 auto; /* Centrar tabla */
}

.turnos-table {
  width: 100%;
  min-width: 700px;
  margin: 0 auto; /* Centrar tabla */
}

.turnos-table th,
.turnos-table td {
  padding: 10px;
  text-align: center;
  white-space: nowrap;
}

/* Calendario */
.calendar-section {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Fecha seleccionada */
.seleccined-date-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Botón del calendario */
.buttoms-calendar {
  margin-top: 20px;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .columns-container {
    flex-direction: column;
    align-items: center; /* Centra las secciones */
  }

  .table-section,
  .calendar-section {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .turnos-table {
    min-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .turnos-page-adm {
    padding: 10px;
  }

  .turnos-table th,
  .turnos-table td {
    padding: 5px;
    font-size: 0.9rem;
  }
}

  </style>