<template>
    <div class="consultorios-container">
      <div class="txt-title-section">CONSULTORIOS</div>
      <div class="links">
        <a href="/consultorios" class="link">| VER CONSULTORIOS</a>
        <a href="/agregar-consultorios" class="link">| NUEVO CONSULTORIO</a>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ConsultoriosComponent', 
  };
  </script>
  





  <style scoped>
  .consultorios-container {
    text-align: center;
    padding-left: 10px;
  }

  .links {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 20px;
  font-size: 24px;
}

  
  
  
  p {
    font-size: 18px;
    color: #333;
  }
  </style>



