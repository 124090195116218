<template>
    <div>
      <h1>Logout</h1>
      <button @click="logout">Cerrar sesión</button>
    </div>
  </template>
  
  <script setup>
  import { useRouter } from 'vue-router';
  
  const router = useRouter();
  
  const logout = async () => {
    try {
      const response = await fetch(`${process.env.VUE_APP_API_URL}/auth-token/logout`, {
        method: 'POST',
        credentials: 'include' // Importante para incluir la cookie
      });
  
      if (response.ok) {
        console.log('Logout exitoso');
        // Redirigir al usuario a la página de inicio de sesión o página pública
        router.push('/login');
      } else {
        console.error('Error al cerrar sesión');
      }
    } catch (error) {
      console.error('Error de red al cerrar sesión:', error);
    }
  };
  </script>
  
  <style scoped>
  button {
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #d32f2f;
  }
  </style>
  