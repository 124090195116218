<template>
  <div class="select-profesional">
    <h2>Seleccionar Profesional</h2>

    <!-- Mostrar la lista de pacientes seleccionados -->
    <div class="recordatorio-selected-paciente">
      Pacientes seleccionados: 
      {{ pacientesSeleccionados.map(paciente => `${paciente.nombre} ${paciente.apellido}`).join(', ') }}
    </div>

    <div class="search-container">
      <select v-model="searchBy">
        <option value="nombre">Nombre</option>
        <option value="apellido">Apellido</option>
      </select>
      <input type="text" v-model="searchTerm" placeholder="Buscar..." />
      <button class="button" @click="buscarProfesional">Buscar</button>
    </div>

    <ul>
      <li
        v-for="profesional in profesionalesFiltrados"
        :key="profesional.guid"
        @click="selectProfesional(profesional.guid)"
        :class="{ 'selected': selectedProfesional === profesional.guid }"
      >
        {{ profesional.nombre }} {{ profesional.apellido }}
      </li>
    </ul>

    <div class="button-container-modal-steps">
      <button class="back-button" @click="emitBack">Atrás</button>
      <button class="button" @click="emitNext" :disabled="!selectedProfesional">
        Siguiente
      </button>
    </div>
  </div>
</template>


<script setup>
import { ref, onMounted, defineEmits, computed, defineProps } from 'vue';

// Estado interno
const profesionales = ref([]);
const selectedProfesional = ref(null);
const searchTerm = ref('');
const searchBy = ref('nombre');

// Props recibidas del padre
const props = defineProps({
  pacientesSeleccionados: {
    type: Array,
    required: true,
  },
});


// Computar nombres completos de los pacientes seleccionados
const pacientesSeleccionados = computed(() => {
  return props.pacientesSeleccionados.map(paciente => {
    return { nombre: paciente.nombre, apellido: paciente.apellido };
  });
});



// Emits para comunicarse con el padre
const emit = defineEmits(['next', 'back']);

// Métodos
const selectProfesional = (guid) => {
  selectedProfesional.value = guid;
};

// Emitir el profesional seleccionado al padre
const emitNext = () => {
  if (selectedProfesional.value) {
    const profesionalSeleccionado = profesionales.value.find(
      (p) => p.guid === selectedProfesional.value
    );

    emit('next', profesionalSeleccionado);
  } else {
    alert('Por favor, selecciona un profesional.');
  }
};

// Volver al paso anterior
const emitBack = () => {
  emit('back');
};

// Filtrado de profesionales
const profesionalesFiltrados = computed(() => {
  if (!searchTerm.value) {
    return profesionales.value; // Mostrar todos los profesionales si no hay término de búsqueda
  }

  const term = searchTerm.value.toLowerCase();
  return profesionales.value.filter((profesional) => {
    const value = profesional[searchBy.value]?.toLowerCase();
    return value?.includes(term);
  });
});

const buscarProfesional = () => {
  // El filtrado se realiza automáticamente a través de profesionalesFiltrados
};

// Obtener la lista de profesionales al montar el componente
onMounted(async () => {
  try {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/adm/profesionales-basic-info`
    );
    if (!response.ok) {
      throw new Error('Error al obtener los profesionales');
    }
    const data = await response.json();
    profesionales.value = data;
  } catch (error) {
    console.error('Error al obtener los profesionales:', error);
  }
});
</script>



  <style scoped>
 

  .select-profesional li.selected {
  background-color: var(--color-light); /* Color de fondo para el seleccionado */
  color: var(--color-text); /* Cambia el color del texto si lo necesitas */
  font-weight: bold; /* Opcional: hace que el texto resalte */
  }

  .select-profesional {
    text-align: left;
  }

  .select-profesional ul {
    list-style: none;
    padding: 0;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 2px;
    color: var(--color-text); 
  }
  
  .select-profesional li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .select-profesional li:hover {
    background-color: #f1f1f1;
  }





  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
 
  @media (max-width: 1024px) {
  .button-container-modal-steps {
    flex-direction: column;
    align-items: center;
  }

  .table-section,
  .calendar-section {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 1024px) {
  .button-container-modal-steps {
    flex-direction: row; /* Mantén la dirección en fila */
    justify-content: space-between; /* Distribuye el espacio entre los botones */
    align-items: center;
  }

  .table-section,
  .calendar-section {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .turnos-table {
    min-width: 100%;
  }

  .button-container-modal-steps {
    display: flex;
    flex-direction: row; /* Mantén la dirección en fila */
    justify-content: space-between; /* Distribuye el espacio entre los botones */
    width: 100%; /* Asegúrate de que el contenedor ocupe todo el ancho */
  }

  .button {
    width: auto; /* Ajuste automático del ancho */
    text-align: center;
  }

  .back-button {
    width: auto; /* Ajuste automático del ancho */
    text-align: center;
  }

  .button:last-child {
    margin-left: 0; /* Elimina el margen izquierdo para dispositivos pequeños */
  }
}

@media (max-width: 480px) {
  .turnos-page-adm {
    padding: 10px;
  }

  .turnos-table th,
  .turnos-table td {
    padding: 5px;
    font-size: 0.9rem;
  }
}


  
  </style>