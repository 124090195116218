<template>
  <h2>Seleccionar Turno</h2>
  <div class="select-turno">
    <div class="recordatorio-selected-paciente-profesional">
      <div class="recordatorio-selected-paciente">
  Pacientes seleccionados: 
  {{ props.pacientesSeleccionados.map(paciente => `${paciente.nombre} ${paciente.apellido}`).join(', ') }}
</div>

      <div class="recordatorio-selected-profesional">
        Profesional seleccionado: {{ profesionalNombreCompleto }}
      </div>
    </div>

    <div class="container-horizontal-head"> 
      <div class="vcalentar-select-fecha-contaier" v-if="mostrarSelectorFecha">
        <v-calendar 
          v-if="true" 
          class="custom-calendar" 
          :attributes="calendarAttributes" 
          @dayclick="onDayClick" 
        />

        <div v-if="!selectedDate" class="no-date-selected">
          <p>Por favor, selecciona una fecha.</p>
        </div>
      </div>

      <div class="buttons-hide-show-calendar-container">
        <button v-if="mostrarSelectorFecha" class="button" @click="hideCalendar">Ocultar Calendario</button>
        <button v-if="!mostrarSelectorFecha" class="button" @click="showCalendar">Mostrar Calendario</button>
      </div>

      <div v-if="selectedDate" class="selected-date">
        <p>
          Fecha seleccionada:
          <strong>{{ fechaFormateada.toUpperCase() }}</strong>
        </p>

        <div v-if="loadingHorarios">
          <p>Cargando horarios...</p>
        </div>
        <div v-else-if="horariosDisponibles && horariosDisponibles.length > 0">
          <h3>Horario de atención de {{ profesionalNombreCompleto }}:</h3>
          <ul>
            <li v-for="horario in horariosDisponibles" :key="horario.hora_inicio">
              {{ horario.hora_inicio }} - {{ horario.hora_fin }}
            </li>
          </ul>

          <SelectHorario
  :fecha="selectedDate"
  :profesional="props.profesional"
  :pacientes="props.pacientesSeleccionados"
  :hora_turno="horariosDisponibles"
  :dia_semana="diaSemana"
/>

        </div>
        <div v-else-if="horariosDisponibles === null">
          <p>El profesional no atiende en este día.</p>
        </div>
        <div v-else-if="!loadingHorarios && selectedDate">
          <p>No hay horarios disponibles para esta fecha.</p>
        </div>
      </div>
    </div>

    <div class="button-container-modal-steps">
      <button class="back-button" @click="emitBack">Atrás</button>
      <button class="button" @click="emitFinish" :disabled="!selectedDate">
        Confirmar
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, onMounted } from 'vue';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import SelectHorario from './SelectHorario.vue';

const props = defineProps({
  pacientesSeleccionados: {
    type: Array,
    required: true,
  },
  profesional: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['finish', 'back']);

const selectedDate = ref(null);
const calendarAttributes = ref([]);
const horariosDisponibles = ref([]);
const loadingHorarios = ref(false);
const mostrarSelectorFecha = ref(true);
const diasValidos = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'];

const diaSemana = ref(null);


const profesionalNombreCompleto = computed(() => {
  return `${props.profesional.nombre} ${props.profesional.apellido}`;
});

const fechaFormateada = computed(() => {
  if (selectedDate.value) {
    const timeZone = 'America/Argentina/Buenos_Aires';
    const fechaUTC = zonedTimeToUtc(selectedDate.value + 'T00:00:00', timeZone);
    const fechaConZonaHoraria = utcToZonedTime(fechaUTC, timeZone);

    return format(fechaConZonaHoraria, 'EEEE dd \'de\' MMMM \'de\' yyyy', { locale: es });
  } else {
    return null;
  }
});

// Función para manejar la selección de un día
const onDayClick = (day) => {
  const timeZone = 'America/Argentina/Buenos_Aires';
  const fechaISO = day.date.toISOString().split('T')[0] + 'T00:00:00';
  const fechaUTC = zonedTimeToUtc(fechaISO, timeZone);

  const formattedDate = format(fechaUTC, 'yyyy-MM-dd');
  selectedDate.value = formattedDate;

  let diaSemanaCalculado = format(fechaUTC, 'EEEE', { locale: es }).toLowerCase();
  diaSemanaCalculado = diaSemanaCalculado.replace('sábado', 'sabado');
  diaSemanaCalculado = diaSemanaCalculado.replace('miércoles', 'miercoles');

  if (!diasValidos.includes(diaSemanaCalculado)) {
    console.error('Día de la semana no válido:', diaSemanaCalculado);
    return;
  }

  diaSemana.value = diaSemanaCalculado;
  consultarDisponibilidadProfesional(props.profesional.guid, diaSemanaCalculado);
};

// Consultar disponibilidad del profesional
const consultarDisponibilidadProfesional = async (guidProfesional, diaSemana) => {
  loadingHorarios.value = true;
  horariosDisponibles.value = [];

  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/turnos/franja-horaria/${guidProfesional}/${diaSemana}`);
    if (!response.ok) {
      console.error('El profesional no atiende en este día:', response.status);
      horariosDisponibles.value = null;
      return;
    }

    const respuesta = await response.json();
    horariosDisponibles.value = respuesta;
  } catch (error) {
    console.error('Error al consultar disponibilidad:', error);
    horariosDisponibles.value = null;
  } finally {
    loadingHorarios.value = false;
  }
};

// Control de calendario
const hideCalendar = () => {
  mostrarSelectorFecha.value = false;
};

const showCalendar = () => {
  mostrarSelectorFecha.value = true;
};

// Emitir evento de finalización con todos los datos
const emitFinish = () => {
  if (selectedDate.value) {
    emit('finish', {
      fecha: selectedDate.value,
      pacientes: props.pacientes,
      profesional: props.profesional,
      horarios: horariosDisponibles.value,
    });
  }
};

const emitBack = () => {
  emit('back');
};

onMounted(() => {
  const today = new Date();
  onDayClick({ date: today });
});
</script>

  <style scoped>
  li{
  list-style-type: none;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr; /* Una sola columna para las horas */
  gap: 5px;
  overflow-y: auto; /* Barra de scroll vertical si es necesario */
  max-height: 400px; /* Altura máxima de la grilla */
}




  h2{text-align: left;}
  
  .buttons-hide-show-calendar-container{
    margin: 10px;
  }


  
  </style>