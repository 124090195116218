<template>
    <div class="turnos-container">
      <div class="txt-title-section">TURNOS</div>
      <div class="links">
        <a href="/turnos" class="link">| VER TURNOS</a>
        <!--  */ <a href="/agregar-turno" class="link">| NUEVO TURNO</a> -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TurnosComponent', 
  };
  </script>
  





  <style scoped>
  .turno-container {
    text-align: center;
    padding-left: 10px;
  }
  

  
  .links {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 20px;
  font-size: 24px;
}

 
  </style>