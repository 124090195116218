<template>
    <div class="profesionales-container">
      <div class="txt-title-section">PROFESIONALES</div>
      <div class="links">
        <a href="/profesionales" class="link">| VER PROFESIONALES</a>
        <a href="/agregar-profesional" class="link">| NUEVO PROFESIONAL</a>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ProfecionalesComponent',
  };
  </script>
  





  <style scoped>
  .profecionales-container {
    text-align: center;
    padding-left: 10px;
  }
  

  
  .links {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 20px;
  font-size: 24px;
}

  

  p {
    font-size: 18px;
    color: #333;
  }
  </style>