<template>
  <AdminHeader/>
 
  <div class="admin">
    <div class="container-aux"></div>

    <AdminDashboardComponent />

    <div class="row">
      <div class="column">
        <PacientesComponent />
      </div>
      <div class="column">
        <ProfesionalesComponent />
      </div>
      <div class="column">
        <ObrasSocialesComponent />
      </div>
    </div>
    <div class="row">
      <div class="column">
        <ConsultoriosComponent />
      </div>
      <div class="column">
        <TurnosComponent />
      </div>
    </div>
  </div>
</template>
    
  <script>
import PacientesComponent from '@/views/AdminViews/AdminComponents/PacientesComponent.vue'
import ProfesionalesComponent from '@/views/AdminViews/AdminComponents/ProfesionalesComponent.vue';
import TurnosComponent from '@/views/AdminViews/AdminComponents/TurnosComponent.vue';
import ObrasSocialesComponent from '@/views/AdminViews/AdminComponents/ObrasSocialesComponent.vue';
import ConsultoriosComponent from '@/views/AdminViews/AdminComponents/ConsultoriosComponent.vue';
import AdminHeader from './AdminComponents/AdminHeader.vue';
import  AdminDashboardComponent from '@/views/AdminViews/AdminDashboardComponent.vue';


  export default {
    name: 'AdminView',
    components: {
      PacientesComponent,
      ProfesionalesComponent,
      TurnosComponent,
      ObrasSocialesComponent,
      ConsultoriosComponent,
      AdminHeader,
      AdminDashboardComponent
    },
    data() {
      return {};
    },
    methods: {}
  };
  </script>
    
  <style>
  .admin {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espacio entre las filas */
  }
  
  .row {
    display: flex;
    justify-content: space-between;
  }
  
  .column {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    margin: 5px;
    text-align: center;
  }
  
  .column:hover {
    transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
    
}
  /* Adaptabilidad a pantallas de celulares */
  @media (max-width: 600px) {
    .row {
      flex-direction: column;
    }
    
    .column {
      margin: 10px 0;
    }
  }
  </style>
  