<template>
  <AdminHeader />
  <div class="pacientes-container">
    <div class="title-section-list">Lista de Pacientes</div>

    <div class="search-container">
      <select v-model="searchBy" class="search-select">
        <option value="nombre">Nombre</option>
        <option value="apellido">Apellido</option>
        <option value="dni">DNI</option>
      </select>
      <input v-model="searchTerm" type="text" class="search-input" placeholder="Ingrese el valor..." />
      <button @click="fetchPacientes" class="button">Buscar</button>
      <router-link :to="`/agregar-paciente`" class="custom-link">
             ( + ) Añadir Paciente
            </router-link>
    </div>

    <table class="pacientes-table">
      <thead>
        <tr>
          <th>DNI</th>
          <th>Nombre</th>
          <th>Apellido</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="paciente in filteredPacientes"
          :key="paciente.dni"
          @click="verPaciente(paciente.guid)"
          class="clickable-row"
        >
          <td>{{ paciente.dni }}</td>
          <td>{{ paciente.nombre }}</td>
          <td>{{ paciente.apellido }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import AdminHeader from "@/views/AdminViews/AdminComponents/AdminHeader.vue";

const pacientes = ref([]);
const searchBy = ref("nombre");
const searchTerm = ref("");

const fetchPacientes = async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/pacientes-basic-info`);
    if (!response.ok) throw new Error("Error al obtener los pacientes");

    pacientes.value = await response.json();
  } catch (error) {
    console.error("Error:", error);
    alert("No se pudieron cargar los pacientes.");
  }
};

// Función para eliminar acentos y normalizar texto
const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
};

const filteredPacientes = computed(() => {
  const term = removeAccents(searchTerm.value);

  if (!term) return pacientes.value;

  return pacientes.value.filter(paciente => {
    const fieldValue = removeAccents(paciente[searchBy.value] || "");
    return fieldValue.includes(term);
  });
});

const router = useRouter();
const verPaciente = (guid) => {
  router.push({ name: "PacienteDetalle", params: { guid } });
};

onMounted(fetchPacientes);
</script>


  
  <style scoped>
  .pacientes-container {
    padding: 20px;

  }  

  td{
    cursor: pointer;
  }



  
  </style>