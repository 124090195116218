<template>
  <transition name="modal">
    <div v-if="visible" class="modal-mask" @click.self="cerrar">
      <div class="modal-container">
        <div class="modal-header">
          <h3>{{ esGrupal ? 'Editar Turno Grupal' : 'Editar Turno' }}</h3>
          <h4>{{ formatoFechaHora(fechaOriginal) }}</h4>
          <button class="modal-close" @click="cerrar">&times;</button>
        </div>
        
        <div class="modal-body">
          <div class="form-group" v-if="diasAtencion.length > 0">
            <label>Día de atención</label>
            <select v-model="diaSeleccionado" @change="actualizarHorario">
              <option value="">Seleccione un día</option>
              <option 
                v-for="dia in diasAtencion" 
                :key="dia" 
                :value="dia"
              >
                {{ dia }}
              </option>
            </select>
          </div>

          <div class="form-group" v-if="horariosDisponibles.length > 0 && diaSeleccionado">
            <label>Horario disponible</label>
            <select v-model="nuevoHorario">
              <option value="">Seleccione un horario</option>
              <option 
                v-for="horario in horariosDisponibles" 
                :key="horario" 
                :value="horario"
              >
                {{ horario }}
              </option>
            </select>
          </div>
          <div v-else-if="diaSeleccionado && !isLoadingHorarios" class="no-horarios">
            No hay horarios disponibles para este día
          </div>

          <div class="form-group">
            <label>Estado</label>
            <select v-model="formData.estado">
              <option value="PENDIENTE">Pendiente</option>
              <option value="CONFIRMADO">Confirmado</option>
              <option value="CANCELADO">Cancelado</option>
            </select>
          </div>
          
          <div class="form-group">
            <label>Observaciones</label>
            <textarea v-model="formData.observaciones"></textarea>
          </div>
          
          <div v-if="esGrupal" class="pacientes-list">
            <h4>Pacientes</h4>
            <div v-for="(paciente, index) in formData.pacientes" :key="index" class="paciente-item">
              {{ paciente.nombre_paciente }} {{ paciente.apellido_paciente }}
            </div>
          </div>
        </div>
        
        <div class="modal-footer">
          <button @click="eliminarTurno" class="btn-eliminar">Eliminar</button>
          
          <button @click="cerrar" class="back-button">Cancelar</button>
          <button @click="guardar">Guardar</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, computed, watch, defineEmits, defineProps, onMounted } from 'vue';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

const props = defineProps({
  visible: Boolean,
  turno: Object,
  guid_profesional: String
});

const emit = defineEmits(['update:visible', 'guardar']);

const formData = ref({
  id_turno: null,
  dia_semana: '',  // Ej: "Lunes"
  hora: '',        // Ej: "14:30"
  estado: 'PENDIENTE',
  observaciones: '',
  is_grupal: false,
  pacientes: [],
  ...props.turno
});

const fechaOriginal = ref(props.turno?.fecha_turno || '');

// Estados para días y horarios
const diasAtencion = ref([]);
const diaSeleccionado = ref('');
const horariosDisponibles = ref([]);
const nuevoHorario = ref('');
const isLoadingDias = ref(false);
const isLoadingHorarios = ref(false);

onMounted(async () => {
  await cargarDiasAtencion();
});

// Cargar días de atención del profesional
const cargarDiasAtencion = async () => {
  if (!props.guid_profesional) return;
  
  isLoadingDias.value = true;
  try {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/adm/profesional/dias-atencion/${props.guid_profesional}`
    );
    
    if (!response.ok) throw new Error('Error al cargar días de atención');
    
    const data = await response.json();
    diasAtencion.value = data.dias_atencion || [];
    
    // Establecer día seleccionado si el turno ya tiene fecha
    if (formData.value.fecha_turno) {
      const fecha = new Date(formData.value.fecha_turno);
      const dias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
      diaSeleccionado.value = dias[fecha.getDay()];
      await cargarHorariosDisponibles(diaSeleccionado.value);
    }
  } catch (error) {
    console.error('Error:', error);
  } finally {
    isLoadingDias.value = false;
  }
};

// Cargar horarios disponibles para un día
const cargarHorariosDisponibles = async (dia) => {
  if (!dia || !props.guid_profesional) return;
  
  isLoadingHorarios.value = true;
  horariosDisponibles.value = [];
  try {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/adm/profesional/horarios-libres-segun-dia/${props.guid_profesional}/${dia}`
    );
    
    if (!response.ok) throw new Error('Error al cargar horarios disponibles');
    
    const data = await response.json();
    horariosDisponibles.value = data.turnosLibres || [];
    
    // Establecer horario seleccionado si el turno ya tiene hora
    if (formData.value.fecha_turno && horariosDisponibles.value.length > 0) {
      const fecha = new Date(formData.value.fecha_turno);
      const horas = fecha.getHours().toString().padStart(2, '0');
      const minutos = fecha.getMinutes().toString().padStart(2, '0');
      const horaFormateada = `${horas}:${minutos}`;
      
      if (horariosDisponibles.value.includes(horaFormateada)) {
        nuevoHorario.value = horaFormateada;
        formData.value.nuevo_horario = horaFormateada;
      }
    }
  } catch (error) {
    console.error('Error:', error);
  } finally {
    isLoadingHorarios.value = false;
  }
};

const actualizarHorario = async () => {
  if (!diaSeleccionado.value) return;
  formData.value.dia_semana = diaSeleccionado.value;
  await cargarHorariosDisponibles(diaSeleccionado.value);
};

watch(nuevoHorario, (nuevoValor) => {
  formData.value.hora = nuevoValor;
});

// Inicializar valores del turno
watch(() => props.turno, (nuevoTurno) => {
  if (nuevoTurno) {
    formData.value = { 
      ...formData.value,
      ...nuevoTurno,
      nuevo_horario: ''
    };
    fechaOriginal.value = nuevoTurno.fecha_turno || '';
    
    // Establecer horario inicial si existe
    if (nuevoTurno.fecha_turno) {
      const fecha = new Date(nuevoTurno.fecha_turno);
      const horas = fecha.getHours().toString().padStart(2, '0');
      const minutos = fecha.getMinutes().toString().padStart(2, '0');
      nuevoHorario.value = `${horas}:${minutos}`;
      formData.value.nuevo_horario = nuevoHorario.value;
    }
  }
}, { immediate: true });

const esGrupal = computed(() => formData.value.is_grupal);

const cerrar = () => {
  emit('update:visible', false);
};

const formatoFechaHora = (fecha) => {
  if (!fecha) return '';
  return format(parseISO(fecha), "EEEE d 'de' MMMM 'de' yyyy 'a las' HH:mm", { locale: es });
};

const guardar = () => {
  if (!formData.value.id_turno) {
    console.error("ID de turno no válido");
    return;
  }
  
  // Prepara los datos a enviar
  const datosTurno = {
    id_recurrente: formData.value.id_turno,
    dia_semana: formData.value.dia_semana,
    nuevo_horario: formData.value.hora,
    estado: formData.value.estado,
    observaciones: formData.value.observaciones
    // ... otros campos necesarios
  };

  console.log("Datos nuevos turno --> ",datosTurno)

  emit('guardar', datosTurno);
};

const eliminarTurno = () => {
  if (confirm('¿Está seguro que desea eliminar este turno?')) {
    emit('eliminar', formData.value.id_turno); // Emite el evento 'eliminar' con el ID del turno
    cerrar(); // Cierra el modal después de eliminar
  }
};
</script>


  
  <style scoped>

/* Fondo del modal */
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Contenedor principal */
.modal-container {
  background: white;
  border-radius: 2px;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Cabecera */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.modal-header h3 {
  margin: 0;
  font-size: 1.2rem;
}

.modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Formulario */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 2px;
  box-sizing: border-box;
}

.form-group textarea {
  min-height: 80px;
}

/* Lista de pacientes */
.pacientes-list {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.paciente-item {
  padding: 5px 0;
}

/* Pie del modal */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}


.btn-eliminar {
  background: #f44336;
  color: white;
  border-color: #f44336;
  margin-right: auto; /* Lo posiciona a la izquierda */
}

.btn-guardar {
  background: #424BB2;
  color: white;
  border-color: #424BB2;
}

/* Transición */
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}



.confirm-modal {
  background-color: rgba(0, 0, 0, 0.7);
}

.confirm-container {
  max-width: 400px;
  text-align: center;
}

.confirm-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

</style>
