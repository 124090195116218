<template>
  <div class="select-paciente">
    <div>
      <div>
        <!-- Mostrar nombres de los pacientes seleccionados -->
        <div>
          {{ "Pacientes seleccionados: " + pacientesSeleccionados.map(paciente => `${paciente.nombre} ${paciente.apellido}`).join(', ') }}
        </div>
      </div>
      <h2>Seleccionar Paciente</h2>
    </div>

    <div class="search-container">
      <select v-model="searchBy">
        <option value="nombre">Nombre</option>
        <option value="apellido">Apellido</option>
        <option value="dni">DNI</option>
      </select>
      <input type="text" v-model="searchTerm" placeholder="Buscar...">
      <button class="button" @click="buscarPaciente">Buscar</button>
    </div>

    <ul>
  <li
    v-for="paciente in pacientesFiltrados"
    :key="paciente.guid"
    @click="togglePacienteSeleccionado(paciente)"
    :class="{ 'selected': isPacienteSeleccionado(paciente) }"  >
  
    {{ paciente.nombre }} {{ paciente.apellido }}
  </li>
</ul>


    <div class="button-container-modal-steps">
      <button class="button" @click="emitNext" :disabled="pacientesSeleccionados.length === 0">
        Siguiente
      </button>
    </div>
  </div>
</template>


<script setup>
import { ref, onMounted, computed, defineProps} from 'vue';
import { defineEmits } from 'vue';

// Estado interno
const pacientes = ref([]);
const pacientesSeleccionados = ref([]);
const searchTerm = ref('');
const searchBy = ref('nombre');

// Emits para comunicarse con el padre
const emit = defineEmits(['next']);
const props = defineProps({
  pacientesSeleccionados: {
    type: Array,
    required: true,
  },
});

// Métodos
const togglePacienteSeleccionado = (paciente) => {
  const index = pacientesSeleccionados.value.findIndex(selPaciente => selPaciente.guid === paciente.guid);
  if (index === -1) {
    pacientesSeleccionados.value.push(paciente);
    console.log("seleccionaste a: ",paciente)
  } else {
    pacientesSeleccionados.value.splice(index, 1);
  }
};

const isPacienteSeleccionado = (paciente) => {
  return pacientesSeleccionados.value.some(selPaciente => selPaciente.guid === paciente.guid);
};

const emitNext = () => {
  if (pacientesSeleccionados.value.length > 0) {
    emit('next', pacientesSeleccionados.value);
  }
};

// Filtrado de pacientes
const pacientesFiltrados = computed(() => {
  if (!searchTerm.value) {
    return pacientes.value; // Mostrar todos los pacientes si no hay término de búsqueda
  }

  const term = searchTerm.value.toLowerCase();
  return pacientes.value.filter(paciente => {
    const value = paciente[searchBy.value]?.toLowerCase();
    return value?.includes(term);
  });
});

const buscarPaciente = () => {
  // El filtrado se realiza automáticamente a través de pacientesFiltrados
};

// Obtener la lista de pacientes al montar el componente
onMounted(async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/pacientes-basic-info`);
    if (!response.ok) {
      throw new Error('Error al obtener los pacientes');
    }
    const data = await response.json();
    pacientes.value = data;
  } catch (error) {
    console.error("Error al obtener los pacientes:", error);
  }

  // Sincronizar el estado local con los pacientes seleccionados del padre
  if (props.pacientesSeleccionados && props.pacientesSeleccionados.length > 0) {
    pacientesSeleccionados.value = [...props.pacientesSeleccionados];
  }

});
</script>

  
  <style scoped>
 
 .select-paciente li.selected {
  background-color: var(--color-light); /* Color de fondo para el seleccionado */
  color: var(--color-text); /* Cambia el color del texto si lo necesitas */
  font-weight: bold; /* Opcional: hace que el texto resalte */
  }

  .select-paciente {
    text-align: left;
  }

  .select-paciente ul {
    list-style: none;
    padding: 0;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 2px;
    color: var(--color-text); 
  }
  
  .select-paciente li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .select-paciente li:hover {
    background-color: #f1f1f1;
  }
  
  .search-container {
  display: flex;
  margin-bottom: 10px;
}



.search-container input {
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 2px;
}


.button-container-modal-steps {
  display: flex;
}

.button:last-child {
  margin-left: auto;
  text-align: right;
}



@media (max-width: 1024px) {
  .button-container-modal-steps {
    flex-direction: column;
    align-items: center;
  }

  .table-section,
  .calendar-section {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .turnos-table {
    min-width: 100%;
  }

  .button-container-modal-steps {
    display: flex;
    justify-content: center; /* Centra los botones */
  }

  .button {
    width: auto; /* Mantén el ancho automático */
    text-align: center;
  }

  .button:last-child {
    margin-left: 0; /* Elimina el margen izquierdo para dispositivos pequeños */
  }
}

@media (max-width: 480px) {
  .turnos-page-adm {
    padding: 10px;
  }

  .turnos-table th,
  .turnos-table td {
    padding: 5px;
    font-size: 0.9rem;
  }
}


  </style>