<template>
  <div class="tabla-container">
    <table>
      <thead>
        <tr>
          <th class="columna-horarios">Horario</th>
          <th class="columna-paciente">Paciente</th>
          <th class="columna-estado">Estado</th>
          <th class="columna-consultorio">Consultorio</th>
          <th class="columna-observaciones">Observaciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="hora in horarios" :key="hora">
          <td :class="{ 'turno-libre': !turnosOcupados[hora], 'horario-no-disponible': !estaDentroDelHorarioDeTrabajo(hora) }" @click="manejarClick(hora)">
            {{ hora }}
          </td>
          <td 
  :class="{ 
    'turno-libre': !turnosOcupados[hora], 
    'horario-no-disponible': !estaDentroDelHorarioDeTrabajo(hora),
    'turno-grupal': turnosOcupados[hora]?.isGrupal 
  }"
  @click="manejarClick(hora)"
>
  <template v-if="turnosOcupados[hora]?.pacientes">
    <div v-if="turnosOcupados[hora].isGrupal">
      <div>Turno Grupal</div>
      <router-link 
        v-for="paciente in turnosOcupados[hora].pacientes" 
        :key="paciente.guid"
        :to="`/pacientes/${paciente.guid}`"
        class="custom-link block"
        @click.stop
      >
        {{ paciente.nombre }} {{ paciente.apellido +" "}}
      </router-link>
    </div>
    <router-link 
      v-else
      :to="`/pacientes/${turnosOcupados[hora].pacientes[0].guid}`"
      class="custom-link"
      @click.stop
    >
      {{ turnosOcupados[hora].pacientes[0].nombre}} {{ turnosOcupados[hora].pacientes[0].apellido }}
    </router-link>
  </template>
  <span v-else>Libre</span>
</td>
          <td :class="{ 'turno-libre': !turnosOcupados[hora], 'horario-no-disponible': !estaDentroDelHorarioDeTrabajo(hora) }" @click="manejarClick(hora)">
            <span :class="{ 'estado-cancelado': turnosOcupados[hora]?.estado === 'Cancelado' }">
              {{ turnosOcupados[hora]?.estado || "Disponible" }}
            </span>
          </td>
          <td :class="{ 'turno-libre': !turnosOcupados[hora], 'horario-no-disponible': !estaDentroDelHorarioDeTrabajo(hora) }" @click="manejarClick(hora)">
            {{ turnosOcupados[hora]?.consultorio || "-" }}
          </td>
          <td :class="{ 'turno-libre': !turnosOcupados[hora], 'horario-no-disponible': !estaDentroDelHorarioDeTrabajo(hora) }" @click="manejarClick(hora)">
            {{ turnosOcupados[hora]?.observaciones || "-" }}
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="modalVisible" class="modal-overlay">
      <div class="modal-content">
        <SetTurnoFinalStep
  :horarioSeleccionado="selectedHora"
  :fecha="props.fecha"
  :profesional="props.profesional"
  :pacientes="props.pacientes"
  :horarios="props.horarios"
  :dia_semana="props.dia_semana"
  @confirmar="recibirDatosFinales"
  @cancelar="cerrarModal"
/>

      </div>
    </div>
  </div>
</template>



  <script setup>
import { ref, onMounted , defineProps} from 'vue';
import SetTurnoFinalStep from './SetTurnoFinalStep.vue';

const horarios = ref([]);
const turnosOcupados = ref({}); // Objeto para almacenar los turnos ocupados

const modalVisible = ref(false);
const selectedHora = ref(null);
const selectedConsultorio = ref("1"); // Valor predeterminado
const observaciones = ref("");


const props = defineProps({
  fecha: {
    type: String,
    required: true,
  },
  profesional: {
    type: Object,
    required: true,
  },
  pacientes: {
    type: Array, // Los pacientes seleccionados
    required: true,
  },
  hora_turno: {
    type: Array,
    required: true,
  },
  dia_semana: {
    type: String,
    required: true,
  },
});


console.log("Fecha recibida en SelectHorario:", props.fecha);
console.log("DiaSemana recibida en SelectHorario:", props.dia_semana);


const manejarClick = (hora) => {
  if (!estaDentroDelHorarioDeTrabajo(hora)) {
    alert("Este horario no está disponible.");
    return;
  }

  seleccionarTurno(hora);
};


onMounted(() => {
  generarHorarios();
  cargarTurnosOcupados();
  console.log("Pacientes seleccionados en SelectHorario:", props.pacientes);
});

const seleccionarTurno = (hora) => {
  console.log("Has seleccionado el turno:", hora);
  if (!turnosOcupados.value[hora]) {
    selectedHora.value = hora;
    modalVisible.value = true;
   
  }
};

//fecha turno ej: 2025-02-03 09:30:00



const recibirDatosFinales = () => {
  // selectedConsultorio.value = datos.consultorio; // Ya no necesitas esto aquí
  // observaciones.value = datos.observaciones; // Ya no necesitas esto aquí
  // confirmarTurno(); // Elimina esta línea
  cerrarModal(); // Cerrar el modal después de confirmar
  cargarTurnosOcupados(); // Recargar los turnos para actualizar la tabla
};


const cerrarModal = () => {
  modalVisible.value = false;
  selectedHora.value = null;
  selectedConsultorio.value = "1";
  observaciones.value = "";
};

const generarHorarios = () => {
  const horariosGenerados = [];

  // Definir el horario completo del día (desde 8:15 hasta 20:00)
  const horaInicioDia = 8 * 60 + 15; // 8:15 en minutos
  const horaFinDia = 20 * 60; // 20:00 en minutos

  // Generar todos los intervalos de 45 minutos dentro del horario completo del día
  let horaActual = horaInicioDia;
  while (horaActual < horaFinDia) {
    // Ajuste para incluir el intervalo de 15:15
    if (horaActual === 15 * 60) { // Si la hora actual es 15:00
      horaActual += 15; // Avanzar 15 minutos para comenzar en 15:15
    }

    const horaInicioIntervalo = `${Math.floor(horaActual / 60)
      .toString()
      .padStart(2, "0")}:${(horaActual % 60).toString().padStart(2, "0")}`;
    horaActual += 45; // Avanzar 45 minutos
    const horaFinIntervalo = `${Math.floor(horaActual / 60)
      .toString()
      .padStart(2, "0")}:${(horaActual % 60).toString().padStart(2, "0")}`;

    // Agregar el intervalo al arreglo
    horariosGenerados.push(`${horaInicioIntervalo} - ${horaFinIntervalo}`);
  }

  horarios.value = horariosGenerados;
  console.log("HORARIOS: ", horariosGenerados);
};



const cargarTurnosOcupados = async () => {
  try {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/turnos/get-turnos-dia-profesional/${props.fecha}/${props.profesional.guid}`
    );
    if (!response.ok) {
      throw new Error(`Error al cargar turnos: ${response.status} ${response.statusText}`);
    }
    const data = await response.json();
    console.log("RESPUESTA DE LA API: ", data);
    turnosOcupados.value = procesarDatosDeLaAPI(data); // Pasar directamente `data`
  } catch (error) {
    console.error("Error al cargar turnos ocupados:", error);
    alert("Error al cargar los turnos. Por favor, inténtelo de nuevo más tarde.");
    turnosOcupados.value = {}; // O un objeto vacío para que la tabla se renderice sin datos
  }
};
const estaDentroDelHorarioDeTrabajo = (hora) => {
  const [horaInicioIntervalo] = hora.split(" - "); // Extraer la hora de inicio del intervalo

  // Convertir la hora de inicio del intervalo a minutos
  const [horaInicioH, horaInicioM] = horaInicioIntervalo.split(":").map(Number);
  const minutosInicioIntervalo = horaInicioH * 60 + horaInicioM;

  // Verificar si el intervalo está dentro de algún horario de trabajo
  return props.hora_turno.some((horarioAtencion) => {
    const [horaInicioTrabajoH, horaInicioTrabajoM] = horarioAtencion.hora_inicio.slice(0, 5).split(":").map(Number);
    const [horaFinTrabajoH, horaFinTrabajoM] = horarioAtencion.hora_fin.slice(0, 5).split(":").map(Number);

    const minutosInicioTrabajo = horaInicioTrabajoH * 60 + horaInicioTrabajoM;
    const minutosFinTrabajo = horaFinTrabajoH * 60 + horaFinTrabajoM;

    return minutosInicioIntervalo >= minutosInicioTrabajo && minutosInicioIntervalo < minutosFinTrabajo;
  });
};
const procesarDatosDeLaAPI = (turnosDesdeAPI) => {
  const turnosProcesados = {};
  if (turnosDesdeAPI && Array.isArray(turnosDesdeAPI)) {
    turnosDesdeAPI.forEach((turno) => {
      // Extraer la hora de `fecha_turno` (formato: "YYYY-MM-DD HH:mm:ss")
      const fechaTurno = new Date(turno.fecha_turno);
      const horaInicio = `${String(fechaTurno.getHours()).padStart(2, "0")}:${String(fechaTurno.getMinutes()).padStart(2, "0")}`;

      // Calcular la hora de fin sumando 45 minutos
      const horaFin = new Date(fechaTurno.getTime() + 45 * 60000); // 45 minutos en milisegundos
      const horaFinFormateada = `${String(horaFin.getHours()).padStart(2, "0")}:${String(horaFin.getMinutes()).padStart(2, "0")}`;

      // Crear el intervalo de horario
      const horario = `${horaInicio} - ${horaFinFormateada}`;

      // Procesar pacientes para turnos grupales
      const pacientes = turno.pacientes
        .filter(paciente => paciente.guid_paciente !== null)
        .map(paciente => ({
          guid: paciente.guid_paciente,
          nombre: paciente.nombre_paciente,
          apellido: paciente.apellido_paciente,
          dni: "", // Asegúrate de que esto se actualice si tienes datos de DNI
        }));

      // Registrar el turno como ocupado
      turnosProcesados[horario] = {
        pacientes: pacientes.length > 0 ? pacientes : null,
        estado: turno.estado,
        consultorio: turno.id_consultorio,
        observaciones: turno.observaciones,
        isGrupal: turno.is_grupal
      };
    });
  }
  return turnosProcesados;
};



</script>
  
 
  
<style scoped>
.tabla-container {
  width: 100%; /* Ancho fijo del contenedor */
  height: 300px; /* Alto fijo del contenedor */
  overflow-y: auto; /* Scroll vertical con diseño moderno */
  border: 1px solid #e0e0e0; /* Borde suave */
  border-radius: 2px; /* Bordes redondeados */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra ligera */
  background-color: #ffffff; /* Fondo blanco */
}


.columna-horarios {
  width: 100px; /* Ancho específico para la columna de horarios */
}

.estado-cancelado {
  color: #ff5252; /* Rojo moderno para estados cancelados */
  font-weight: bold; /* Negrita para resaltar */
}

.turno-libre {
  background-color: #e8f5e9; /* Verde claro para turnos libres */
  cursor: pointer; /* Cursor de mano para indicar interacción */
  transition: background-color 0.3s ease; /* Transición suave al pasar el mouse */
}

.turno-libre:hover {
  background-color: #c8e6c9; /* Cambio de color al pasar el mouse */
}

.horario-no-disponible {
  background-color: #f5f5f5; /* Gris claro para horarios no disponibles */
  color: #bdbdbd; /* Texto gris */
  cursor: not-allowed; /* Cursor de no permitido */
}

.modal-overlay {
  position: fixed; /* Cubre toda la pantalla */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Fondo oscuro semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegura que esté sobre otros elementos */
}

.modal-content {
  padding: 20px;
  border-radius: 12px; /* Bordes redondeados más pronunciados */
  background-color: #ffffff; /* Fondo blanco */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada */
  max-width: 500px; /* Ancho máximo del modal */
  width: 100%; /* Ajusta el ancho según el contenido */
  z-index: 1010; /* Asegura que el modal hijo esté sobre el overlay */
}





.custom-link {
  color: #3498db; /* Cambia este valor al color que prefieras */
  text-decoration: none;
  font-weight: bold;
}

.custom-link:hover {
  color: #2980b9; /* Cambia este valor al color que prefieras para el hover */
  text-decoration: underline;
}
.turno-grupal {
 
  
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.turno-grupal:hover {
  
  
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Subtle shadow for depth */
}

</style>