<template>
    <div class="historial-container">
      <h2 class="titulo">Historial del Profesional</h2>

      <div class="historial-list">
        <div v-for="(evento, index) in historial" :key="index" class="historial-item">
          <p><strong>Fecha:</strong> {{ evento.fecha }}</p>
          <p><strong>Descripción:</strong> {{ evento.descripcion }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from "vue";
  
  const historial = ref([
    { fecha: "2025-01-01", descripcion: "Consulta con paciente Juan Pérez" },
    { fecha: "2025-01-15", descripcion: "Reunión con equipo médico" },
    { fecha: "2025-02-01", descripcion: "Evaluación de seguimiento" },
  ]);




onMounted(() => {
});


  </script>
  




  <style scoped>
  .historial-container {

    border-radius: 2px;
   
    
  }
  
  .titulo {
    margin-bottom: 1rem;
  }
  
  .historial-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .historial-item {
 
    padding: 1rem;
    border-radius: 2px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  } 
  </style>
  