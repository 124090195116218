<template>
  <AdminHeader />
  <ProfesionalDetallesHeader v-if="profesional" :profesional="profesional" />
  <div class="contenedor-principal">
    <div v-if="profesional" class="detalle-profesional-container">
      <div class="columna perfil">
        <div class="perfil-content">
          <div class="foto-profesional">
            <img :src="fotoProfesionalUrl" alt="Foto del profesional" />
          </div>
          <span class="nombre-profesional">{{ profesional.nombre }} {{ profesional.apellido }}</span>
          <div> {{ profesional.especialidad }}</div>
          <button class="button-secondary" @click="mostrarDatosPersonales = !mostrarDatosPersonales">
  {{ mostrarDatosPersonales ? 'Ocultar Datos Personales' : 'Mostrar Datos Personales' }}
</button>

          <Transition name="fade">
          <div v-if="mostrarDatosPersonales" class="datos-container">
            <div class="datos-seccion datos-personales">
              <p class="seccion-titulo">DATOS PERSONALES</p>
              <p><strong>Nombre:</strong> {{ profesional.nombre }}</p>
              <p><strong>Apellido:</strong> {{ profesional.apellido }}</p>
              <p><strong>DNI:</strong> {{ profesional.dni }}</p>
            </div>

            <div class="datos-seccion datos-administrativos">
              <p class="seccion-titulo">DATOS ADMINISTRATIVOS</p>
              <p><strong>Matrícula:</strong> {{ profesional.matricula }}</p>
              <p><strong>Especialidad:</strong> {{ profesional.especialidad }}</p>
              <p><strong>Fecha de Ingreso:</strong> {{ profesional.fecha_ingreso }}</p>
            </div>

            <div class="datos-seccion datos-contacto">
              <p class="seccion-titulo">DATOS DE CONTACTO</p>
              <p><strong>Teléfono:</strong> {{ profesional.nro_telefono }}</p>
              <p><strong>Email:</strong> {{ profesional.email }}</p>
            </div>

            <div class="datos-seccion domicilio-datos">
              <p class="seccion-titulo">DOMICILIO</p>
              <div class="domicilio-detalles">
                <p><strong>Localidad:</strong> {{ profesional.localidad }}</p>
                <p><strong>Calle:</strong> {{ profesional.calle }}</p>
                <p><strong>Altura:</strong> {{ profesional.altura }}</p>
                <p><strong>Piso:</strong> {{ profesional.piso }}</p>
                <p><strong>Observaciones:</strong> {{ profesional.observaciones }}</p>
              </div>
            </div>
            
          </div>
        </Transition>
          

          <div class="separador"></div>
          <p class="seccion-titulo">HORARIOS DE ATENCION</p>
          <div class="horarios-atencion">
    <!-- Mostrar mensaje si no hay horarios -->
    <p v-if="horariosAtencion.length === 0">
      No hay horarios de atención asignados para este profesional.
    </p>

    <!-- Lista de horarios -->
    <ul v-else>
      <li v-for="horario in horariosAtencion" :key="horario.dia_semana + horario.hora_inicio">
        {{ horario.dia_semana }}: {{ horario.hora_inicio }} - {{ horario.hora_fin }}
        <span v-if="editarHorarios" class="icon-trash" @click="eliminarHorario(horario.id_disponibilidad)">
          <i class="fas fa-trash"></i>
        </span>
      </li>
    </ul>

    <!-- Botones de edición -->
    <button class="button-secondary" v-if="editarHorarios" @click="mostrarFormulario = !mostrarFormulario">
      {{ mostrarFormulario ? 'Cancelar' : 'Agregar Horario' }}
    </button>
    <button class="button-secondary" @click="editarHorarios = !editarHorarios">
      {{ editarHorarios ? 'Listo' : 'Modificar Horarios' }}
    </button>

    <!-- Formulario de nuevo horario -->
    <div v-if="editarHorarios && mostrarFormulario" class="formulario-horario">
      <label for="dia_semana">Día de la semana:</label>
      <select id="dia_semana" v-model="nuevoHorario.dia_semana">
        <option value="Lunes">Lunes</option>
        <option value="Martes">Martes</option>
        <option value="Miércoles">Miércoles</option>
        <option value="Jueves">Jueves</option>
        <option value="Viernes">Viernes</option>
        <option value="Sábado">Sábado</option>
        <option value="Domingo">Domingo</option>
      </select>

      <label for="hora_inicio_hora">Hora de inicio:</label>
      <select id="hora_inicio_hora" v-model="nuevoHorario.hora_inicio_hora">
        <option v-for="hora in horas" :key="hora" :value="hora">{{ hora }}</option>
      </select>
      <select id="hora_inicio_minuto" v-model="nuevoHorario.hora_inicio_minuto">
        <option v-for="minuto in minutos" :key="minuto" :value="minuto">{{ minuto }}</option>
      </select>

      <label for="hora_fin_hora">Hora de fin:</label>
      <select id="hora_fin_hora" v-model="nuevoHorario.hora_fin_hora">
        <option v-for="hora in horasFin" :key="hora" :value="hora" :disabled="hora < nuevoHorario.hora_inicio_hora">
          {{ hora }}
        </option>
      </select>
      <select id="hora_fin_minuto" v-model="nuevoHorario.hora_fin_minuto">
        <option v-for="minuto in minutos" :key="minuto" :value="minuto">{{ minuto }}</option>
      </select>

      <button class="button-primary" @click="agregarHorario">Guardar Horario</button>
    </div>
  </div>
<div class="separador"></div>
          <div class="pacientes-del-profesional">
            <p class="seccion-titulo">PACIENTES ASIGNADOS</p>
            <div class="paciente" v-for="(paciente, index) in pacientes" :key="paciente.guid_paciente">
              <div class="paciente-preview" @click="togglePacienteInfo(index)">
                <div class="paciente-preview-picture">
                  <img :src="paciente.profile_picture || defaultProfilePicture" alt="Foto del paciente" class="foto-paciente" />
                </div>
                <div class="paciente-preview-name">
                  {{ paciente.nombre + " " + paciente.apellido }}
                </div>
              </div>
              <div v-if="mostrarDatosPaciente[index]" class="info-paciente">
                <p @click="redirigirPaciente(paciente.guid_paciente)">
                  <strong>Nombre:</strong> <span class="p-name">{{ paciente.nombre }} {{ paciente.apellido }} </span>
                </p>
                <p><strong>DNI:</strong> {{ paciente.dni }}</p>
                <p><strong>Tipo de Turno:</strong> {{ paciente.tipo_turno }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="columna">
        <HistorialProfesional />
      </div>
      <div class="columna">
        <TurnosDelDiaProfesional :guid_profesional="guid" /> 
      </div>
      <div class="columna "> <DocumentacionProfesional :guid_profesional="guid"/> </div>
    </div>
    <div v-else class="cargando">Cargando...</div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import AdminHeader from "@/views/AdminViews/AdminComponents/AdminHeader.vue";
import HistorialProfesional from '@/views/AdminViews/AdminComponents/ProfesionalesComponent/HistorialProfesional.vue'
import ProfesionalDetallesHeader from "./AdminComponents/ProfesionalesComponent/ProfesionalDetallesHeader.vue";
import TurnosDelDiaProfesional from "./AdminComponents/ProfesionalesComponent/TurnosDelDiaProfesional.vue";
import DocumentacionProfesional from "./AdminComponents/ProfesionalesComponent/DocumentacionProfesional.vue";


const route = useRoute();
const router = useRouter();
const editarHorarios = ref(false);

const profesional = ref(null);
const horariosAtencion = ref([]);

const mostrarDatosPersonales = ref(false);
const mostrarDatosPaciente = ref({});
const fotoProfesionalUrl = require('@/assets/default-profile.jpg');
const defaultProfilePicture = require('@/assets/default-profile.jpg');
const pacientes = ref([]);

// Estado del formulario
const mostrarFormulario = ref(false);
const nuevoHorario = ref({
  dia_semana: "",
  hora_inicio: "",
  hora_fin: "",
});







const guid = route.params.guid;
const cargarDatosProfesional = async () => {
  try {
    // Obtener datos básicos del profesional
    const responseProfesional = await fetch(`${process.env.VUE_APP_API_URL}/adm/profesional/${guid}`);
    const dataProfesional = await responseProfesional.json();
    profesional.value = dataProfesional;
    console.log("datos profesional: ",dataProfesional)
    // Llamar a cargarPacientesAsignados si es necesario
    cargarPacientesAsignados();
  } catch (error) {
    console.error("Error al cargar los datos del profesional:", error);
  }
};

const cargarHorariosProfesional = async () => {
  try {
    // Obtener horarios de atención del profesional
    const responseHorarios = await fetch(`${process.env.VUE_APP_API_URL}/adm/profesional/horarios-atencion/${guid}`);
    const dataHorarios = await responseHorarios.json();
    horariosAtencion.value = dataHorarios;
    console.log("horarios de atencion",horariosAtencion.value)
  } catch (error) {
    console.error("Error al cargar los horarios del profesional:", error);
  }
};


const cargarPacientesAsignados = async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/profesional/${guid}/pacientes`);
    const data = await response.json();
    pacientes.value = data;
  
  } catch (error) {
    console.error("Error al cargar los pacientes asignados:", error);
  }
};

const togglePacienteInfo = (index) => {
  mostrarDatosPaciente.value[index] = !mostrarDatosPaciente.value[index];
};

const redirigirPaciente = (pacienteGuid) => {
  router.push(`/pacientes/${pacienteGuid}`);
};

const horas = ref(Array.from({ length: 13 }, (_, i) => (i + 8).toString().padStart(2, '0'))); // 08 - 20
const minutos = ref(['00', '15', '30', '45']);

const horasFin = computed(() => {
  return horas.value.filter(hora => hora >= nuevoHorario.value.hora_inicio_hora);
});

const agregarHorario = async () => {
    try {
        if (!nuevoHorario.value.dia_semana || !nuevoHorario.value.hora_inicio_hora || !nuevoHorario.value.hora_inicio_minuto || !nuevoHorario.value.hora_fin_hora || !nuevoHorario.value.hora_fin_minuto) {
            alert("Por favor, complete todos los campos.");
            return;
        }
        let hora_inicio = nuevoHorario.value.hora_inicio_hora + ':' + nuevoHorario.value.hora_inicio_minuto + ':00';
        let hora_fin = nuevoHorario.value.hora_fin_hora + ':' + nuevoHorario.value.hora_fin_minuto + ':00';
        const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/profesional/insertar-horario`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                guid_profesional: guid,
                dia_semana: nuevoHorario.value.dia_semana,
                hora_inicio: hora_inicio,
                hora_fin: hora_fin,
            }),
        });
        const data = await response.json();
        if (response.ok) {
            nuevoHorario.value = {
                dia_semana: "",
                hora_inicio_hora: '08',
                hora_inicio_minuto: '00',
                hora_fin_hora: '08',
                hora_fin_minuto: '00',
            };
            mostrarFormulario.value = false;
            alert("Horario agregado correctamente.");
            cargarHorariosProfesional();
        } else {
            alert(`Error: ${data.error}`);
        }
    } catch (error) {
        console.error("Error al agregar el horario:", error);
        alert("Ocurrió un error al agregar el horario.");
    }
};

// Función para eliminar un horario
const eliminarHorario = async (id_disponibilidad) => {
  try {
    // Confirmar antes de eliminar
    const confirmacion = confirm(`¿Estás seguro de que deseas eliminar el horario con ID ${id_disponibilidad}?`);
    if (!confirmacion) return;

    // Enviar solicitud DELETE al backend
    const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/profesional/eliminar-horario/${id_disponibilidad}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    });

    const data = await response.json();

    if (response.ok) {
      // Eliminar el horario de la lista local
      horariosAtencion.value = horariosAtencion.value.filter((h) => h.id_disponibilidad !== id_disponibilidad);

      alert("Horario eliminado correctamente.");
    } else {
      alert(`Error: ${data.error}`);
    }
  } catch (error) {
    console.error("Error al eliminar el horario:", error);
    alert("Ocurrió un error al eliminar el horario.");
  }
};
onMounted(() => {
  cargarDatosProfesional();
  cargarHorariosProfesional();
});
</script>



<style scoped>

button{
  margin: 5px;
}

.contenedor-principal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: #f4f4f4;
  }
  

  

  .detalle-profesional-container {
  display: flex;
  flex-wrap: wrap; 
  gap: 20px;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ocupa todo el ancho disponible */
  max-width: 1200px; /* Ancho máximo opcional - ¡Ajusta según tu diseño! */
  margin: 0 auto; /* Centra horizontalmente */
}






.columna {
  flex: 1;  
  min-width: 0; 
}

.perfil {
    flex: 0 0 auto; 
                     
    width: auto;     
    max-width: 100%;  
    
    @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
    }
}
.aux{
  flex: 1 1 0; 
  min-width: 0;
}
  
  .perfil-content {
    text-align: center;
    padding: 20px;
  }
  
  .foto-profesional {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .foto-profesional img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #ddd;
  }
  
  .nombre-profesional {
    font-size: 1.4rem;
    font-weight: bold;
    color: #333;
  }
  

  
  .datos-container {
    margin-top: 15px;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    
  }
  
  .datos-seccion {
    margin-bottom: 15px;
  }
  
  .seccion-titulo {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 8px;
    color: #444;
    text-transform: uppercase;
  }
  
  p {
    margin: 4px 0;
    color: #555;
  }
  
  .pacientes-del-profesional {
    margin-top: 20px;
  }
  
  .paciente {
    background: #fff;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }
  
  .paciente-preview {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
  }
  
  .paciente-preview:hover {
    background: #eee;
  }
  
  .paciente-preview-picture img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .paciente-preview-name {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
  }
  
  .info-paciente {
    margin-top: 10px;
    background: #fafafa;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .p-name {
    color: red;
    cursor: pointer;
  }
  
  .p-name:hover {
    text-decoration: underline;
  }
  
  .cargando {
    font-size: 1.2rem;
    color: #666;
  }
  

  .horarios-atencion ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.horarios-atencion li {
  display: flex;
  justify-content: space-between; /* Distribuye el texto y el icono */
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd; /* Línea divisoria */
  background-color: #f9f9f9; /* Color de fondo */
  width: 100%;
}

.horarios-atencion li:hover {
  background-color: #f1f1f1; /* Cambio de color al pasar el mouse */
}

.horarios-atencion .icon-trash {
  cursor: pointer;
  color: #d9534f; /* Color rojo */
  transition: color 0.2s ease-in-out;
}

.horarios-atencion .icon-trash:hover {
  color: #c9302c; /* Rojo más oscuro al pasar el mouse */
}

.formulario-horario {
  margin-top: 10px;
}
.formulario-horario label {
  display: block;
  margin-bottom: 5px;
}
.formulario-horario input,
.formulario-horario select {
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
}




/* CSS para la transición */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease; /* Animación de opacidad */
}

.fade-enter-from, /* Estado inicial al entrar */
.fade-leave-to { /* Estado final al salir */
  opacity: 0;
}


</style>
