<template>
    <div class="documentacion-container">
      <h2> Documentos </h2>
      <button @click="mostrarSeccion = !mostrarSeccion" >
        Documentación
      </button>
  
      <div v-if="mostrarSeccion" class="seccion-documentos">
        <div class="subir-archivo-container">
          <input type="file" id="archivoInput" ref="archivoInput" @change="handleFileChange" accept=".pdf" style="display: none;" />
          <label for="archivoInput" class="boton-subir">Seleccionar Archivo (PDF)</label>
          <div v-if="archivoSeleccionado">
            <p>Archivo seleccionado: {{ archivoSeleccionado.name }}</p>
            <input type="text" v-model="titulo" placeholder="Título del documento" class="input-titulo" />
            <textarea v-model="descripcion" placeholder="Descripción del documento" class="input-detalle"></textarea>
            <button @click="subirArchivo" class="boton-subir">Subir Documento</button>
          </div>
          <button @click="obtenerDocumentos" class="boton-obtener">Obtener Documentos</button> <br>
        </div>
  
        <div class="documentos-grid">
          <div v-for="documento in documentos" :key="documento.id" class="documento-card">
            <h3 class="documento-titulo">{{ documento.titulo }}</h3>
            <p class="documento-detalle">{{ documento.descripcion }}</p>
            <p class="documento-fecha">Fecha de subida: {{ formatoFecha(documento.fechaSubida) }}</p>
            <a :href="documento.url" target="_blank" rel="noopener noreferrer" class="documento-link">
              Ver/Descargar
            </a>
            <button @click="eliminarDocumento(documento.id)" class="boton-eliminar">Eliminar</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, defineProps } from 'vue';
  
  const mostrarSeccion = ref(false);
  const documentos = ref([]);
  const archivoSeleccionado = ref(null);
  const titulo = ref('');
  const descripcion = ref('');
  const archivoInput = ref(null);
  
  const props = defineProps({
  guid_profesional: {
    type: [String, Number],
    required: true,
  },
});

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      archivoSeleccionado.value = file;
    } else {
      archivoSeleccionado.value = null;
      alert('Por favor, selecciona un archivo PDF válido.');
    }
  };
  
  const subirArchivo = async () => {
    console.log('Archivo seleccionado:', archivoSeleccionado.value);
    console.log('Título:', titulo.value);
    console.log('Descripción:', descripcion.value);
  
    if (!archivoSeleccionado.value || !titulo.value.trim() || !descripcion.value.trim()) {
      alert('Por favor, selecciona un archivo, proporciona un título y una descripción.');
      return;
    }
  
    const formData = new FormData();
formData.append('archivo', archivoSeleccionado.value);
formData.append('titulo', titulo.value);
formData.append('descripcion', descripcion.value);
formData.append('guid_profesional', props.guid_profesional);

// Debug: Verifica que el FormData tenga `guid_profesional`
for (let pair of formData.entries()) {
  console.log(pair[0], pair[1]);  // Asegúrate de que `guid_profesional` se envía correctamente
}

    
  

    try {
      const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/documentos`, { 
        method: 'POST',
        body: formData,
        credentials: 'include'
      });
  
      if (response.ok) {
        await obtenerDocumentos();
  
        archivoSeleccionado.value = null;
        titulo.value = '';
        descripcion.value = '';
        if (archivoInput.value) {
          archivoInput.value.value = null;
        }
        alert('Archivo subido correctamente!');
  
      } else {
        if (response.status === 409) {
          alert('Ya existe un archivo con el mismo nombre o contenido. Cambia el nombre o sube otro archivo.');
        } else {
          const errorData = await response.json();
          console.error('Error al subir el archivo:', errorData);
          alert(`Error al subir el archivo: ${errorData.message || 'Error desconocido'}`);
        }
      }
  
    } catch (error) {
      console.error('Error de red:', error);
      alert('Hubo un error de red al subir el archivo. Por favor, inténtalo de nuevo.');
    }
  };
  
  const obtenerDocumentos = async () => {
    try {
      const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/documentos/`, {
        method: 'GET',
        credentials: 'include'
      });
      if (response.ok) {
        documentos.value = await response.json();
      } else {
        const errorData = await response.json();
        console.error('Error al obtener documentos:', errorData);
      
      }
    } catch (error) {
      console.error('Error de red:', error);
      alert('Hubo un error de red al obtener los documentos.');
    }
  };
  
  const eliminarDocumento = async (id) => {
    if (!confirm('¿Estás seguro de que quieres eliminar este documento?')) {
      return;
    }
  
    try {
      const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/documentos/${id}`, {
        method: 'DELETE',
      });
  
      if (response.ok) {
        await obtenerDocumentos();
        alert('Documento eliminado correctamente.');
      } else {
        const errorData = await response.json();
        console.error('Error al eliminar documento:', errorData);
        alert(`Error al eliminar documento: ${errorData.message || 'Error desconocido'}`);
      }
    } catch (error) {
      console.error('Error de red:', error);
      alert('Hubo un error de red al eliminar el documento.');
    }
  };
  
  onMounted(obtenerDocumentos);
  
  const formatoFecha = (fechaISO) => {
    if (!fechaISO) return '';
    const fecha = new Date(fechaISO);
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const anio = fecha.getFullYear();
    return `${dia}/${mes}/${anio}`;
  };
  </script>

  
  
  <style scoped>
  .documentacion-container {
   
  }
  
  .seccion-documentos {
    margin-top: 10px;

  
     /* Elimina el padding si quieres que las tarjetas lleguen a los bordes */
  }
  
  .documentos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
    gap: 16px; /* Espacio entre tarjetas */
    padding: 16px; /* Espacio interior */
    /* No necesitas max-width ni width aquí, ya lo controla documentacion-container */
  }
  
  .documento-card {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;  /* Espacio interior en las tarjetas */
  
   
  }
  
  .documento-titulo {
    font-size: 1.1rem; /* Ligeramente más pequeño */
    font-weight: bold;
    margin-bottom: 8px; /* Menos margen */
    color: #333;
  }
  
  .documento-detalle {
    margin-bottom: 8px;
    color: #555;
    overflow-wrap: break-word;
    font-size: 0.9rem; /* Texto un poco más pequeño */
  }
  
  .documento-fecha {
    font-size: 0.8rem; /* Aún más pequeño */
    color: #777;
    margin-bottom: 8px;
  }
  
  .documento-link {
    display: inline-block; /* Permite aplicar padding y margin */
    padding: 8px 12px;
    background-color: #4CAF50; /* Color de fondo */
    color: white;
    text-decoration: none; /* Quita el subrayado */
    border-radius: 4px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease; /* Transición suave */
    margin-top: auto; /* Empuja el enlace hacia abajo */
    align-self: flex-start;
  }
  
  .documento-link:hover {
      background-color: #367c39;
  }
  </style>