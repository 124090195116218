<template>
  <AdminHeader/>
  <div class="form-container">
    <h2>Agregar Consultorio</h2>
    <form @submit.prevent="submitForm" class="formulario">
      <label class="form-label">Nombre del Consultorio:
        <input v-model="consultorio.nombre_consultorio" type="text" />
      </label>
      <label class="form-label">Observaciones:
        <textarea v-model="consultorio.observacion"></textarea>
      </label>
      <label class="form-label">
        Activo:
        <input v-model="consultorio.isActive" type="checkbox" />
      </label>
      <button type="submit">Agregar Consultorio</button>
    </form>
    <button @click="toggleConsultorios" class="button" id="button-show-hide-container-consultorios-existentes">
      {{ mostrarConsultorios ? 'Ocultar Consultorios' : 'Mostrar Consultorios Disponibles' }}
    </button>
    
    <div class="container-consultorios-existentes" v-if="mostrarConsultorios && consultorios.length > 0">
      <div class="separador"></div>
      <h3>Consultorios Disponibles:</h3>
      <ul class="lista-consultorios">
        <li v-for="consultorio in consultorios" :key="consultorio.id_consultorio">
          <router-link :to="`/consultorio/${consultorio.id_consultorio}`" class="custom-link">
            {{ consultorio.nombre_consultorio }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import AdminHeader from "@/views/AdminViews/AdminComponents/AdminHeader.vue";

const consultorio = ref({
  nombre_consultorio: "",
  observacion: "",
  isActive: false,
});
const consultorios = ref([]);
const mostrarConsultorios = ref(false); // Nuevo ref para controlar la visibilidad

const resetForm = () => {
  consultorio.value = {
    nombre_consultorio: "",
    observacion: "",
    isActive: false,
  };
};

const submitForm = async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/turnos/create-consultorio`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(consultorio.value),
    });

    if (!response.ok) {
      throw new Error("Error al crear el consultorio");
    }

    console.log("Consultorio guardado:", consultorio.value);
    alert("Consultorio creado exitosamente");
    resetForm();
    if (mostrarConsultorios.value) {
      await fetchConsultorios();
    }
  } catch (error) {
    console.error("Error al crear el consultorio:", error);
    alert("Hubo un error al crear el consultorio. Por favor, inténtelo de nuevo más tarde.");
  }
};

const fetchConsultorios = async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/turnos/get-consultorios`);
    if (!response.ok) {
      throw new Error("Error al obtener los consultorios");
    }
    consultorios.value = await response.json();
  } catch (error) {
    console.error("Error al obtener los consultorios:", error);
    alert("Hubo un error al obtener los consultorios. Por favor, inténtelo de nuevo más tarde.");
  }
};

const toggleConsultorios = async () => {
  mostrarConsultorios.value = !mostrarConsultorios.value;
  if (mostrarConsultorios.value && consultorios.value.length === 0) {
    await fetchConsultorios();
  }
};
</script>

<style scoped>



button{
  margin: 10px;
}


#button-show-hide-container-consultorios-existentes {
  opacity: 0.7;
}

.container-consultorios-existentes {
  margin: 5px;
}



.lista-consultorios{
  list-style: none; /* Quita los puntos */
  padding-left: 0; /* Elimina el padding izquierdo predeterminado */
  text-align: start;
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  padding: 10px;
}
</style>
