<template>
  <AdminHeader />
  <div class="obras-sociales-container">
    <div class="title-section-list">Lista de Obras Sociales</div>

    <div class="search-container">
      <select v-model="searchBy" class="search-select">
        <option value="nombre">Nombre</option>
      </select>
      <input v-model="searchTerm" type="text" class="search-input" placeholder="Ingrese el valor..." />
      <button @click="fetchObrasSociales" class="button">Buscar</button>

      <label class="checkbox-label">
        <input type="checkbox" v-model="showInactive" />
        Mostrar inactivas
      </label>

      <router-link :to="`/agregar-obras-sociales`" class="custom-link">
             ( + ) Añadir O.S
            </router-link>


    </div>

    <table class="obras-sociales-table">
      <thead>
        <tr>
          <th>Nombre Obra Social</th>
          <th>Estado</th>
          <th>Observaciones</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="obraSocial in filteredObrasSociales"
          :key="obraSocial.id_obra_social"
          :class="{ 'inactive-row': !obraSocial.isActive }"
          class="clickable-row"
        >
          <td>{{ obraSocial.nombre_os }}</td>
          <td>{{ obraSocial.isActive ? "Activa" : "Inactiva" }}</td>
          <td>{{ obraSocial.observaciones || 'Sin observaciones' }}</td>
        </tr>
      </tbody>
    </table>
    
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import AdminHeader from "@/views/AdminViews/AdminComponents/AdminHeader.vue";

const obrasSociales = ref([]);
const searchBy = ref("nombre");
const searchTerm = ref("");
const showInactive = ref(false);

const fetchObrasSociales = async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/os`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) throw new Error("Error al obtener las Obras Sociales");

    obrasSociales.value = await response.json();
  } catch (error) {
    console.error("Error:", error);
    alert("No se pudieron cargar las Obras Sociales.");
  }
};

const filteredObrasSociales = computed(() => {
  return obrasSociales.value.filter(os => {
    const matchesName = os.nombre_os.toLowerCase().includes(searchTerm.value.toLowerCase());
    const matchesActive = showInactive.value || os.isActive;
    return matchesName && matchesActive;
  });
});

onMounted(() => {
  fetchObrasSociales();
});
</script>

<style scoped>
.obras-sociales-container {
  padding: 20px;
}


.checkbox-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
 
}

.checkbox-label input {
  margin-right: 5px;
}

/* Diferenciación visual para inactivas */
.inactive-row {
  background-color: #f0f0f0;
  color: #777;
  opacity: 0.7;
}


</style>
