<template>
  <AdminHeader />
  <PacientesDetallesHeader v-if="paciente" :paciente="paciente" />
  <div class="contenedor-principal">
    <div v-if="paciente" class="detalle-paciente-container">
      <!-- Primera columna: Información del perfil y foto -->
      <div class="columna perfil">
        <div class="perfil-content">
          <div class="foto-paciente">
            <img :src="fotoPacienteUrl" alt="Foto del paciente" />
          </div>
          <span class="nombre-paciente">{{ paciente.nombre }} {{ paciente.apellido }}</span>
          <button @click="mostrarDatosPersonales = !mostrarDatosPersonales">
            Mostrar Datos Personales
          </button>

          <div v-if="mostrarDatosPersonales" class="datos-container">
            <!-- Datos Personales -->
            <div class="datos-seccion datos-personales">
              <p class="seccion-titulo">DATOS PERSONALES</p>
              <p><strong>Nombre:</strong> {{ paciente.nombre }}</p>
              <p><strong>Apellido:</strong> {{ paciente.apellido }}</p>
              <p><strong>DNI:</strong> {{ paciente.dni }}</p>
              <p> <strong>Fecha de nacimiento: </strong> {{ fechasFormateadas.fechaNacimiento }} </p>
            </div>

            <!-- Datos Administrativos -->
            <div class="datos-seccion datos-administrativos">
              <p class="seccion-titulo">DATOS ADMINISTRATIVOS</p>
              <p><strong>Obra Social:</strong> {{ paciente.nombre_os }}</p>
              <p><strong>Año de Ingreso a Vínculos:</strong> {{ fechasFormateadas.fechaIngreso }}</p>
              <p><strong>Vencimiento CUD:</strong> {{ fechasFormateadas.fechaVencimientoCUD }}</p>
            </div>

            <!-- Datos Familiares -->
            <div class="datos-seccion datos-familiares">
              <p class="seccion-titulo">DATOS FAMILIARES</p>
              <p><strong>Madre:</strong> {{ paciente.madre }}</p>
              <p><strong>Teléfono Madre:</strong> {{ paciente.nro_tel_madre }}</p>
              <p><strong>Padre:</strong> {{ paciente.padre }}</p>
              <p><strong>Teléfono Padre:</strong> {{ paciente.nro_tel_padre }}</p>
              <p><strong>Teléfono de Urgencia:</strong> {{ paciente.telefono_caso_urgencia }}</p>
            </div>

            <!-- Datos Médicos -->
            <div class="datos-seccion datos-medicos">
              <p class="seccion-titulo">DATOS MÉDICOS</p>
              <p><strong>Diagnóstico:</strong> {{ paciente.diagnostico }}</p>
              <p><strong>Médico Neurólogo:</strong> {{ paciente.medico_neurologo }}</p>
              <p><strong>Psiquiatra:</strong> {{ paciente.psiquiatra }}</p>
              <p><strong>Pediatra:</strong> {{ paciente.pediatra }}</p>
              <p><strong>Medicación:</strong> {{ paciente.medicacion }}</p>
              <p><strong>Alergias:</strong> {{ paciente.alergico }}</p>
            </div>

            <!-- Domicilio -->
            <div class="datos-seccion domicilio-datos">
              <p class="seccion-titulo">DOMICILIO</p>
              <div class="domicilio-detalles">
                <p><strong>Localidad:</strong> {{ paciente.localidad }}</p>
                <p><strong>Calle:</strong> {{ paciente.calle }}</p>
                <p><strong>Altura:</strong> {{ paciente.altura }}</p>
                <p><strong>Piso:</strong> {{ paciente.piso }}</p>
                <p><strong>Observaciones:</strong> {{ paciente.observaciones }}</p>
              </div>
            </div>
          </div>
          
          <div class="separador"></div>

          <!-- Profesionales del paciente -->
          <div class="profesionales-del-paciente">
            <p class="seccion-titulo">EQUIPO</p>
            <div class="profesional" v-for="(profesional, index) in profesionales" :key="profesional.guid">
              <div class="profesional-preview" @click="toggleProfesionalInfo(index)">
                <div class="profesional-preview-picture">
                  <img :src="profesional.profile_picture || defaultProfilePicture" alt="Foto del profesional" class="foto-profesional" />
                </div>
                <div class="profesional-preview-name">
                  {{ profesional.nombre + " " + profesional.apellido }}
                </div>
              </div>
              <div v-if="mostrarDatosProfesional[index]" class="info-profesional">
                    <p @click="redirigirProfesional(profesional.guid)">
                    <strong>Nombre:</strong> <span class="p-name">{{ profesional.nombre }} {{ profesional.apellido }} </span>
                  </p>
                  <p><strong>DNI:</strong> {{ profesional.dni }}</p>
                  <p><strong>Teléfono:</strong> {{ profesional.nro_telefono }}</p>
                  <p><strong>Activo:</strong> {{ profesional.activo ? 'Sí' : 'No' }}</p>
</div>

            </div>
          </div>
        </div>
      </div>

      <!-- Otras columnas -->
      <div class="columna">
        <CronologiaPaciente v-if="false" />
      </div>
      <div class="columna paciente-turnos">
  <TurnosDelDiaPaciente :guid_paciente="route.params.guid" />
</div>

  


    </div>
    <div v-else class="cargando">Cargando...</div>
  </div>
</template>

  
  <script setup>
  import { ref, onMounted, computed } from 'vue';
  import { useRoute } from 'vue-router';
  import AdminHeader from '@/views/AdminViews/AdminComponents/AdminHeader.vue';
  
  import CronologiaPaciente from '@/views/AdminViews/AdminComponents/PacientesComponent/CronologiaPaciente.vue'
  import PacientesDetallesHeader from './AdminComponents/PacientesComponent/PacientesDetallesHeader.vue';
  import TurnosDelDiaPaciente from './AdminComponents/PacientesComponent/TurnosDelDiaPaciente.vue';


  const fotoPacienteUrl = require('@/assets/default-profile.jpg');
  const defaultProfilePicture = require('@/assets/default-profile.jpg'); // Imagen por defecto para profesionales sin foto
  
  const route = useRoute();
  const paciente = ref(null);
  const profesionales = ref([]);
  const mostrarDatosPersonales = ref(false);
  const mostrarDatosProfesional = ref([]); // Array para controlar la visibilidad de cada profesional
  
  const extraerFecha = (fechaISO) => {
  if (!fechaISO) return '';
  return fechaISO.substring(0, 10);
};



  const fetchPacienteDetalle = async () => {
    try {
      const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/paciente/${route.params.guid}`);
      if (!response.ok) {
        throw new Error('Error al obtener los detalles del paciente');
      }
      paciente.value = await response.json();
      console.log("datos paciente      ",paciente.value)
      
    } catch (error) {
      console.error(error);
      alert('Error al obtener los detalles del paciente');
    }
  };
  
  const fetchProfesionalesDetalle = async () => {
    try {
      const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/profesionales-detalle-por-paciente/${route.params.guid}`);
      if (!response.ok) {
        throw new Error('Error al obtener los profesionales del paciente');
      }
      profesionales.value = await response.json();
      mostrarDatosProfesional.value = Array(profesionales.value.length).fill(false); // Inicializar el array de visibilidad
    } catch (error) {
      console.error(error);
      alert('Error al obtener los profesionales del paciente');
    }
  };
  
  const toggleProfesionalInfo = (index) => {
    mostrarDatosProfesional.value[index] = !mostrarDatosProfesional.value[index];
  };
  

  const redirigirProfesional = (prof_guid_redirect) => {
    alert(prof_guid_redirect); // This will show the GUID in an alert
    window.location.href = `/profesional/${prof_guid_redirect}`; // This will redirect to the new URL
}
  

const fechasFormateadas = computed(() => {
  if (!paciente.value) return {}; // Si no hay datos, devolver un objeto vacío
  
  return {
    fechaIngreso: paciente.value.ano_ingreso_vinculos ? extraerFecha(paciente.value.ano_ingreso_vinculos) : "",
    fechaNacimiento: paciente.value.fecha_nacimiento ? extraerFecha(paciente.value.fecha_nacimiento) : "",
    fechaVencimientoCUD: paciente.value.vencimiento_CUD ? extraerFecha(paciente.value.vencimiento_CUD) : "",
  };
});


  onMounted(async () => {
    await fetchPacienteDetalle();
    await fetchProfesionalesDetalle();
  });
  </script>
  
  
  
  <style scoped>
 .contenedor-principal {
    display: grid; /* Usamos grid */
    grid-template-columns: 15% repeat(3, 28.33%); /* Definimos los anchos de las columnas */
    gap: 10px; /* Espacio entre columnas */
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 2px;
    margin: 10px;
    /* Nuevas reglas */
    overflow: hidden; /* Evita que el contenido desborde */
    box-sizing: border-box; /* Asegura que los bordes y paddings se respeten */
}


.detalle-paciente-container {
    display: contents; /* Para que las columnas hijas se comporten como si estuvieran directamente en el grid */
   
}

.columna {
    min-height: 600px;
    margin: 0; /* Ya no es necesario con gap en grid */
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
    
    /* Asegura que se respete el padding del contenedor principal */
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box; /* Incluye el padding dentro del ancho total de la columna */
}

 
.paciente-turnos {
    grid-column: span 2; /* Ocupa dos columnas */
    max-width: calc(66.66% - 20px); /* Restringe el ancho al espacio de 2 columnas menos los gaps */
    padding-right: 10px; /* Espacio a la derecha */
    box-sizing: border-box; /* Asegura que el padding no aumente el ancho total */
    overflow: hidden; /* Evita que el contenido interno se desborde */
}

.perfil-content { /* Estilos para el nuevo contenedor */
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
   
}



  .foto-paciente {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  
  .foto-paciente img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
   
  }
  
  .nombre-paciente {
    font-weight: bold;
    font-size: 1.2em;
    color: #333;
    margin-bottom: 10px;
  }
  
 
  
  .cargando {
    text-align: center;
    font-size: 18px;
    color: #888;
    padding: 20px;
  }



  .datos-container {
  
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.datos-container p {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: start;
}




.datos-seccion {
  background-color: #f0f8ff;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 2px;
  box-sizing: border-box;
}

.seccion-titulo {
  font-size: 1.1rem;
  font-weight: bold;
  color: #0056b3;
  text-align: center;
  margin-bottom: 2px;
}

.domicilio-datos {
 
}


  .separador {
    margin-top: 20px;
    height: 1px; /* Ajusta este valor según sea necesario */
  background-color: transparent; /* Puedes cambiar el color si quieres que sea visible */
  border-bottom: 2px solid #eee; /* Añadir borde solo en la parte superior */
  width: 100%; /* Asegurar que el ancho sea del 100% */
  box-sizing: border-box; /* Incluir el borde en el cálculo del ancho */
 
  }


  .profesionales-del-paciente {
    color: gray;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-top: 20px; /* Ajusta este valor según sea necesario */
  margin-bottom: 20px; 
 
}



.profesionales-del-paciente p{
    font-weight: bold;
    font-size: 1.1rem;
    color: gray;
    margin-top: 0px;
}

.profesional {
  font-size: 1rem;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 1px solid #ddd;
  color: black;



}



.profesional-preview {
    display: flex;
 
    align-items: center; /* Alinea verticalmente la imagen y el texto */
    font-size: 0.9rem;

}

.profesional-preview-picture {
  margin-right: 10px; /* Espacio entre la imagen y el nombre */
}



.foto-profesional {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.nombre-profesional {
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

.info-profesional {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
  cursor: default;
  
}

.info-profesional p {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
}

.info-profesional .p-name {
  color: rgb(58, 96, 222);
  
  cursor: pointer;
}






  </style>
  