<template>
  <div class="turnos-container">
    <h2>Todos los Turnos del Paciente</h2>

    <!-- Tabla de turnos -->
    <table>
      <thead>
        <tr>
          <th>Horario</th>
          <th v-for="dia in diasConTurnos" :key="dia">{{ dia }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="horario in horarios" :key="horario">
          <td>{{ horario }}</td>
          <td v-for="dia in diasConTurnos" :key="dia">
            <div v-if="turnosPorDia[horario] && turnosPorDia[horario][dia]">
              <span v-if="turnosPorDia[horario][dia].id_grupal">
                <strong>Turno Grupal</strong> - 
              </span>
              {{ turnosPorDia[horario][dia].nombre_profesional + " " + turnosPorDia[horario][dia].apellido_profesional }} -
              {{ turnosPorDia[horario][dia].nombre_especialidad}} 
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Mensaje de error -->
    <p v-if="error" class="error">{{ error }}</p>
  </div>
</template>


<script setup>
import { ref, onMounted, defineProps } from "vue";

const props = defineProps({
  guid_paciente: {
    type: [String, Number],
    required: true,
  },
});

// Variables reactivas
const turnos = ref([]);
const error = ref(null);
const turnosPorDia = ref({});
const diasConTurnos = ref([]);
const horarios = ref([]);

// Función para organizar los turnos
const organizarTurnosPorDia = (turnos) => {
  const turnosPorHorario = {};
  const horariosDisponibles = new Set();
  const diasEncontrados = new Set();

  turnos.forEach((turno) => {
    const horario = turno.hora;
    const dia = turno.dia_semana;

    if (!turnosPorHorario[horario]) {
      turnosPorHorario[horario] = {};
    }

    turnosPorHorario[horario][dia] = turno;
    horariosDisponibles.add(horario);
    diasEncontrados.add(dia); // Registrar los días que tienen turnos
  });

  horarios.value = Array.from(horariosDisponibles).sort((a, b) => {
    const [horaA, minutoA] = a.split(":").map(Number);
    const [horaB, minutoB] = b.split(":").map(Number);
    return horaA - horaB || minutoA - minutoB;
  });

  diasConTurnos.value = Array.from(diasEncontrados).sort((a, b) => {
    const ordenDias = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
    return ordenDias.indexOf(a) - ordenDias.indexOf(b);
  });

  return turnosPorHorario;
};

// Función para obtener los turnos del paciente
const fetchTurnos = async () => {
  error.value = null;
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/turnos/get-todos-turnos-paciente/${props.guid_paciente}`);
    if (!response.ok) {
      throw new Error(`Error al obtener turnos: ${response.status}`);
    }
    const data = await response.json();
    turnos.value = data;
    turnosPorDia.value = organizarTurnosPorDia(data);
    console.log("Turnos organizados: ", turnosPorDia.value);
  } catch (err) {
    error.value = err.message;
    console.error("Error al obtener los turnos:", err);
  }
};

// Llamada inicial para obtener turnos
onMounted(() => {
  fetchTurnos();
});
</script>
  
  <style scoped>
  
  .turnos-container {
    width: calc(100% - 20px); /* Asegura que se respete el padding lateral (10px a cada lado) */
    max-width: calc(100% - 20px); /* Evita que sobresalga del contenedor */
}

table {
    width: 100%; /* La tabla ocupará el espacio disponible dentro de su contenedor */
    max-width: 100%; /* Evita el desbordamiento horizontal */
    table-layout: fixed; /* Fija el tamaño de las columnas */
    margin: 0 auto; /* Centra la tabla si hay espacio adicional */
}

td, th {
    word-wrap: break-word; /* Evita desbordamiento de contenido largo */
    
}


div {
  overflow: hidden; /* Evitar que elementos internos sobrepasen su contenedor */
  text-overflow: ellipsis; /* Opcional: Para truncar contenido muy largo */
}
  </style>