<template>
  <AdminHeader />
  <div class="consultorios-container">
    <div class="title-section-list">Lista de Consultorios</div>
    <div class="search-container">
      <select v-model="searchBy" class="search-select">
        <option value="nombre_consultorio">Nombre</option>
      </select>
      <input v-model="searchTerm" type="text" class="search-input" placeholder="Ingrese el valor..." />
      <button @click="searchConsultorios" class="button">Buscar</button>
    </div>
    <table class="consultorios-table">
      <thead>
        <tr>
          <th>Nombre del Consultorio</th>
          <th>Observaciones</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="consultorio in consultorios"
          :key="consultorio.id_consultorio"
          @click="consultorio.isActive ? verConsultorio(consultorio.id_consultorio) : null"
          :class="{ 'celda-no-disponible': !consultorio.isActive }"
          class="clickable-row"
        >
          <td>{{ consultorio.nombre_consultorio }}</td>
          <td>{{ consultorio.observacion }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AdminHeader from "@/views/AdminViews/AdminComponents/AdminHeader.vue";

export default {
  name: 'ConsultoriosPageADM',
  components: { AdminHeader },
  data() {
      return {
          consultorios: [],
          searchBy: 'nombre_consultorio',
          searchTerm: ''
      };
  },
  methods: {
      async fetchConsultorios() {
          try {
              const response = await fetch(`${process.env.VUE_APP_API_URL}/turnos/get-consultorios`);
              if (!response.ok) {
                  throw new Error('Error al obtener los consultorios');
              }
              const data = await response.json();
              this.consultorios = data;
          } catch (error) {
              console.error("Error al obtener los consultorios:", error);
              alert("Hubo un error al obtener los consultorios. Por favor, inténtelo de nuevo más tarde.");
          }
      },
      searchConsultorios() {
          if (!this.searchTerm.trim()) {
              alert('Ingrese un valor para buscar');
              return;
          }
          console.log(`Buscando consultorio por ${this.searchBy}: ${this.searchTerm}`);
          // Lógica para buscar en la API o filtrar localmente
      },
      verConsultorio(id) {
          alert(`Has hecho clic en el consultorio con ID: ${id}`);
          // Redirigir o mostrar más detalles
      }
  },
  mounted() {
      this.fetchConsultorios(); // Llamamos a fetchConsultorios cuando el componente se monta
  }
};
</script>



<style scoped>
.consultorios-container {
  padding: 20px;
  
}

.celda-no-disponible {
background-color: #f5f5f5; /* Gris claro para consultorios no disponibles */
color: #bdbdbd; /* Texto gris */
cursor: not-allowed; /* Cursor de no permitido */
}
</style>

