<template>
  <AdminHeader />
      <div class="form-container">
      <h2>Agregar Obra Social</h2>
      <form @submit.prevent="confirmarAgregarObraSocial" >
        <label class="form-label">
          Nombre:
          <input v-model="nuevaObraSocial.nombre" type="text" required />
        </label>
        <label class="form-label">
          Observaciones:
          <textarea v-model="nuevaObraSocial.observaciones" rows="4"></textarea>
        </label>

        <button type="submit">Agregar Obra Social</button>
      </form>

      <button @click="toggleObraSociales" class="ver-os">
        {{ showObraSociales ? 'Ocultar Obras Sociales' : 'Mostrar Obras Sociales' }}
      </button>

      <!-- Columna derecha (lista de obras sociales) -->
      <div v-if="showObraSociales">
        <div class="separador"></div>
        <h3>Obras Sociales Registradas</h3>
        <ul v-if="obrasSociales.length > 0" class="lista-obras-sociales">
          <li v-for="obra in obrasSociales" :key="obra.id_obra_social">
            <router-link :to="`/obra-social/${obra.id_obra_social}`" class="custom-link">
              <strong>{{ obra.nombre_os }}</strong>
            </router-link>
          </li>
        </ul>
        <p v-else>No hay obras sociales registradas.</p>
      </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import AdminHeader from "@/views/AdminViews/AdminComponents/AdminHeader.vue";

const nuevaObraSocial = ref({
  nombre: '',
  id_creador: 1, // ID por defecto
  observaciones: ''
});

const obrasSociales = ref([]);
const showObraSociales = ref(false);

// Función para obtener las obras sociales
const fetchObrasSociales = async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/os`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) {
      throw new Error("Error al obtener las obras sociales.");
    }

    obrasSociales.value = await response.json();
  } catch (error) {
    console.error("Error:", error);
  }
};

// Alternar visibilidad de la lista de obras sociales
const toggleObraSociales = () => {
  showObraSociales.value = !showObraSociales.value;
  if (showObraSociales.value) {
    fetchObrasSociales();
  }
};

// Agregar una obra social
const confirmarAgregarObraSocial = () => {
  const confirmacion = window.confirm(
    `¿Está seguro de que desea agregar la siguiente obra social?\n\n` +
    `Nombre: ${nuevaObraSocial.value.nombre}\n` +
    `Observaciones: ${nuevaObraSocial.value.observaciones || 'Ninguna'}`
  );

  if (confirmacion) {
    agregarObraSocial();
  }
};

const agregarObraSocial = async () => {
  if (!nuevaObraSocial.value.nombre.trim()) {
    alert("El nombre es obligatorio.");
    return;
  }

  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/os`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        nombre_os: nuevaObraSocial.value.nombre.trim(),
        id_creador: 1, // ID por defecto
        observaciones: nuevaObraSocial.value.observaciones || null
      })
    });

    if (!response.ok) {
      throw new Error("Error al agregar la obra social.");
    }

    alert("Obra Social agregada correctamente.");
    nuevaObraSocial.value = { nombre: "", id_creador: 1, observaciones: "" };
    fetchObrasSociales(); // Recargar la lista
  } catch (error) {
    console.error("Error:", error);
    alert(`Error: ${error.message}`);
  }
};

// Cargar obras sociales al montar el componente
onMounted(() => {
  fetchObrasSociales();
});
</script>




<style scoped>

/* Columnas */
.columna {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Asignación de ancho a las columnas */
.izquierda {
  flex: 2; /* Más ancho */
}

.derecha {
  flex: 1; /* Más angosto */
}

button{
  margin: 10px;
}

button.ver-os {
  opacity: 0.4; /* Ajusta este valor entre 0 (transparente) y 1 (opaco) */
  transition: opacity 0.3s ease; /* Agrega una transición suave */
}

button.ver-os:hover {
  opacity: 1; /* Vuelve completamente opaco al pasar el mouse */
}


/* Eliminar los puntos de la lista */
.lista-obras-sociales {
  list-style: none; /* Quita los puntos */
  padding-left: 0; /* Elimina el padding izquierdo predeterminado */
  text-align: start;
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  padding: 10px;
}

/* Estilos para los enlaces */
.enlace-obra-social {
  text-decoration: none; /* Quita el subrayado */
  color: #333; /* Color de texto oscuro */
  display: block; /* Ocupa todo el ancho disponible */
  padding: 8px 0; /* Espaciado interno */
  transition: background-color 0.3s ease; /* Transición suave */
}


</style>
