<template>
    <div class="pacientes-container">

      <div class="txt-title-section">PACIENTES</div>
    
      <div class="links">
        <a href="/pacientes" class="link">| VER PACIENTES</a>
        <a href="/agregar-paciente" class="link">| NUEVO PACIENTE</a>
      <!--  <a href="/grupos" class="link">| GRUPOS</a> -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PacientesComponent', 
  };
  </script>
  





  <style scoped>
  .pacientes-container {
    text-align: center;
    padding-left: 10px;
  }
  

  
  .links {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 20px;
  font-size: 24px;
}

  
 
  
  p {
    font-size: 18px;
    color: #333;
  }
  </style>