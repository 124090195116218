// @/views/AdminViews/AdminComponents/DashboardComponent.vue
<template>
  <div class="dashboard-container">
    <h2>Estadísticas Generales</h2>

    <div v-if="isLoading" class="loading">Cargando estadísticas...</div>
    <div v-if="error" class="error">{{ error }}</div>

    <div v-if="!isLoading && !error && summaryData">

      <div class="stats-grid summary-grid">
         <div class="stat-card summary-card">
           <div class="stat-value">{{ summaryData.profesionales_activos ?? 'N/A' }}</div>
           <div class="stat-label">Profesionales Activos</div>
         </div>
         <div class="stat-card summary-card">
           <div class="stat-value">{{ summaryData.pacientes_activos ?? 'N/A' }}</div>
           <div class="stat-label">Pacientes Activos</div>
         </div>
         <div class="stat-card summary-card">
           <div class="stat-value">{{ summaryData.consultorios_activos ?? 'N/A' }}</div>
           <div class="stat-label">Consultorios Activos</div>
         </div>
          <div class="stat-card summary-card">
           <div class="stat-value">{{ summaryData.total_turnos_recurrentes ?? 'N/A' }}</div>
           <div class="stat-label">Turnos Recurrentes Totales</div>
         </div>
          <div class="stat-card summary-card">
           <div class="stat-value">{{ summaryData.os_activas ?? 'N/A' }}</div>
           <div class="stat-label">Obras Sociales Activas</div>
         </div>
         <div class="stat-card summary-card">
           <div class="stat-value">{{ summaryData.total_especialidades ?? 'N/A' }}</div>
           <div class="stat-label">Especialidades Totales</div>
         </div>
      </div>

      <div class="stats-grid main-grid">

        <div class="stat-card">
          <h3 class="card-title">Resumen General</h3>
          <p><span class="stat-label">Profesionales Activos:</span> {{ summaryData.profesionales_activos ?? 'N/A' }}</p>
          <p><span class="stat-label">Profesionales Inactivos:</span> {{ summaryData.profesionales_inactivos ?? 'N/A' }}</p>
          <p><span class="stat-label">Pacientes Activos:</span> {{ summaryData.pacientes_activos ?? 'N/A' }}</p>
          <p><span class="stat-label">Pacientes Inactivos:</span> {{ summaryData.pacientes_inactivos ?? 'N/A' }}</p>
          <p><span class="stat-label">Promedio Edad Pacientes:</span> {{ parseFloat(summaryData.promedio_edad_pacientes).toFixed(1) ?? 'N/A' }} años</p>
          <hr>
          <p><span class="stat-label">Consultorios Activos:</span> {{ summaryData.consultorios_activos ?? 'N/A' }}</p>
          <p><span class="stat-label">Consultorios Inactivos:</span> {{ summaryData.consultorios_inactivos ?? 'N/A' }}</p>
           <hr>
          <p><span class="stat-label">Obras Sociales Activas:</span> {{ summaryData.os_activas ?? 'N/A' }}</p>
          <p><span class="stat-label">Obras Sociales Inactivas:</span> {{ summaryData.os_inactivas ?? 'N/A' }}</p>
           <hr>
          <p><span class="stat-label">Grupos Activos:</span> {{ summaryData.grupos_activos ?? 'N/A' }}</p>
          <p><span class="stat-label">Grupos Inactivos:</span> {{ summaryData.grupos_inactivos ?? 'N/A' }}</p>
        </div>

         <div class="stat-card">
           <h3 class="card-title">Turnos Recurrentes</h3>
           <p><span class="stat-label">Total Registrados:</span> {{ summaryData.total_turnos_recurrentes ?? 'N/A' }}</p>
           <p><span class="stat-label">Individuales:</span> {{ summaryData.turnos_recurrentes_individuales ?? 'N/A' }}</p>
           <p><span class="stat-label">Grupales:</span> {{ summaryData.turnos_recurrentes_grupales ?? 'N/A' }}</p>
           <hr>
           <h4 class="sub-title">Asignaciones</h4>
           <p><span class="stat-label">Profesionales Sin T. Recurrente:</span> {{ summaryData.profesionales_sin_recurrente ?? 'N/A' }}</p>
           <p><span class="stat-label">Pacientes Sin T. Recurrente:</span> {{ summaryData.pacientes_sin_recurrente ?? 'N/A' }}</p>
         </div>

        <div class="stat-card">
          <h3 class="card-title">Especialidades</h3>
           <p><span class="stat-label">Total Especialidades:</span> {{ summaryData.total_especialidades ?? 'N/A' }}</p>
          <h4 class="sub-title">Profesionales por Especialidad:</h4>
          <ul v-if="specialtiesData.length > 0">
            <li v-for="(esp, index) in specialtiesData" :key="index">
              {{ esp.nombre_especialidad }}: {{ esp.total_profesionales }}
            </li>
          </ul>
           <p v-else>No hay datos de especialidades.</p>
        </div>

        <div class="stat-card">
          <h3 class="card-title">Obras Sociales (Pacientes)</h3>
           <p><span class="stat-label">Activas:</span> {{ summaryData.os_activas ?? 'N/A' }} / <span class="stat-label">Inactivas:</span> {{ summaryData.os_inactivas ?? 'N/A' }}</p>
          <h4 class="sub-title">Distribución de Pacientes:</h4>
          <ul v-if="obrasSocialesData.length > 0">
             <li v-for="(os, index) in obrasSocialesData.filter(o => o.total_pacientes > 0).slice(0, 7)" :key="index">
               {{ os.nombre_obra_social }}: {{ os.total_pacientes }} paciente(s) ({{ parseFloat(os.porcentaje_pacientes).toFixed(1) }}%)
            </li>
             <li v-if="obrasSocialesData.filter(o => o.total_pacientes > 0).length > 7">... y más</li>
             <li v-if="obrasSocialesData.filter(o => o.total_pacientes > 0).length === 0">Sin pacientes asignados</li>
          </ul>
           <p v-else>No hay datos de obras sociales.</p>
        </div>

      </div> </div> </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

// --- Estado Reactivo ---
const summaryData = ref(null);
const specialtiesData = ref([]);
const obrasSocialesData = ref([]);
const isLoading = ref(true);
const error = ref(null);

// --- Lógica de Fetching ---
onMounted(async () => {
  isLoading.value = true;
  error.value = null;
  try {
    // Asegúrate que la ruta '/api/estadisticas-generales' sea correcta
    // o reemplázala por la ruta completa si es necesario (ej: 'http://localhost:3000/api/...')
    const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/estadisticas/estadisticas-generales`);

    if (!response.ok) {
      // Intenta obtener más detalles del error si es posible
      let errorMsg = `Error HTTP: ${response.status}`;
      try {
          const errData = await response.json();
          errorMsg = errData.error || errorMsg;
      } catch(e) { /* No hacer nada si el cuerpo del error no es JSON */ }
      throw new Error(errorMsg);
    }

    const data = await response.json();

    // Validar estructura básica esperada
    if (!data || !data.summary || !data.specialties || !data.obrasSociales) {
        throw new Error("La respuesta de la API no tiene el formato esperado.");
    }

    summaryData.value = data.summary;
    specialtiesData.value = data.specialties;
    obrasSocialesData.value = data.obrasSociales;

  } catch (e) {
    console.error("Error fetching statistics:", e);
    error.value = `No se pudieron cargar las estadísticas: ${e.message}`;
  } finally {
    isLoading.value = false;
  }
});

// --- Helper Functions (Opcional, solo si se usan en la plantilla) ---
// Podrías necesitar formatear números si no usas toLocaleString directamente
// function formatNumber(num) {
//   if (num === null || num === undefined) return 'N/A';
//   return num.toLocaleString('es-AR');
// }

// formatCurrency y calculatePercentage no parecen necesarios con los datos actuales

</script>

<style scoped>
/* Estilos generales */
.dashboard-container {
  padding: 20px;
  background-color: var(--color-light);
  color: var(--color-h1);
  border-radius: 2px;
}

.dashboard-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--color-h1);
}

.loading {
  font-size: 16px;
  color: var(--color-secondary);
  text-align: center;
  margin-top: 20px;
}

.error {
  font-size: 16px;
  color: var(--color-danger);
  text-align: center;
  margin-top: 20px;
}

.stats-grid {
  display: grid;
  gap: 20px;
}

.summary-grid {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.main-grid {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-top: 30px;
}

/* Estilos para las tarjetas */
.stat-card {
  background-color: white;
  border: 1px solid var(--color-light);
  border-radius: 2px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.summary-card {
  text-align: center;
  background-color: var(--color-violet);
  color: white;
  border: none;
}

.summary-card .stat-value {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 5px;
}

.summary-card .stat-label {
  font-size: 14px;
}

.stat-card .card-title {
  font-size: 18px;
  margin-bottom: 15px;
  color: var(--color-violet);
}

.stat-card .sub-title {
  font-size: 16px;
  margin: 15px 0 10px;
  color: var(--color-h1);
}

.stat-card p {
  margin: 5px 0;
  font-size: 14px;
}

.stat-card .stat-label {
  font-weight: bold;
  color: var(--color-secondary);
}

.stat-card ul {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.stat-card ul li {
  font-size: 14px;
  margin: 5px 0;
}

.stat-card hr {
  border: none;
  border-top: 1px solid var(--color-light);
  margin: 15px 0;
}

/* Hover effects */
.stat-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.summary-card:hover {
  background-color: rgba(var(--color-violet-rgb), 0.9);
}

/* Formato especial para números y monedas */
.format-number {
  font-weight: bold;
  color: var(--color-success);
}

.format-currency {
  font-weight: bold;
  color: var(--color-danger);
}
</style>
