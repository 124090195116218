<template>
  <AdminHeader />
  <div class="form-container paciente-add-container">
    <h2>Agregar Paciente</h2>
    <form @submit.prevent="submitForm" class="formulario">
      <label class="form-label">Nombre:
        <input v-model="paciente.nombre" type="text" required />
      </label>
      <label class="form-label">Apellido:
        <input v-model="paciente.apellido" type="text" required />
      </label>
      <label class="form-label">DNI:
        <input v-model="paciente.dni" type="text" required />
      </label>
      <label class="form-label">Fecha de Nacimiento:
        <input v-model="paciente.fecha_nacimiento" type="date" required />
      </label>

      <!-- Domicilio -->
      <div class="domicilio-fila">
        <label class="form-label">Localidad:
          <input v-model="paciente.domicilio.localidad" type="text" required />
        </label>
        <label class="form-label">Calle:
          <input v-model="paciente.domicilio.calle" type="text" />
        </label>
        <label class="form-label">Altura:
          <input v-model="paciente.domicilio.altura" type="number" />
        </label>
        <label class="form-label">Piso:
          <input v-model="paciente.domicilio.piso" type="text" />
        </label>
        <label class="form-label">Observaciones:
          <input v-model="paciente.domicilio.observaciones" type="text" />
        </label>
      </div>

      <!-- Contactos -->
      <label class="form-label">Madre:
        <input v-model="paciente.madre" type="text" />
      </label>
      <label class="form-label">Teléfono Madre:
        <input v-model="paciente.nro_tel_madre" type="text" />
      </label>
      <label class="form-label">Padre:
        <input v-model="paciente.padre" type="text" />
      </label>
      <label class="form-label">Teléfono Padre:
        <input v-model="paciente.nro_tel_padre" type="text" />
      </label>
      <label class="form-label">Teléfono Urgencia:
        <input v-model="paciente.telefono_caso_urgencia" type="text" required />
      </label>

      <!-- Obra Social -->
      <label class="form-label">Obra Social:
        <select v-model="paciente.obra_social">
          <option v-for="obra in obrasSociales" :key="obra.id_obra_social" :value="obra.id_obra_social">{{ obra.nombre_os }}</option>
        </select>
      </label>
      <button class="button-2" type="button" @click="agregarObraSocial">Agregar nueva O.S</button>
      <button class="button-2" type="button" @click="cargarObrasSociales">Actualizar lista de O.S</button>

      <label class="form-label" id="venc_cud">Vencimiento de CUD:
        <input v-model="paciente.vencimiento_CUD" type="date" />
      </label>

      <!-- Información médica -->
      <label class="form-label">Diagnóstico:
        <textarea v-model="paciente.diagnostico"></textarea>
      </label>
      <label class="form-label">Médico Neurólogo:
        <input v-model="paciente.medico_neurologo" type="text" />
      </label>
      <label class="form-label">Psiquiatra:
        <input v-model="paciente.psiquiatra" type="text" />
      </label>
      <label class="form-label">Pediatra:
        <input v-model="paciente.pediatra" type="text" />
      </label>
      <label class="form-label">Medicación:
        <input v-model="paciente.medicacion" type="text" />
      </label>
      <label class="form-label">Alergias:
        <input v-model="paciente.alergico" type="text" />
      </label>
      <label class="form-label">Año de Ingreso:
        <input v-model="paciente.ano_ingreso_vinculos" type="date" />
      </label>

      <!-- Mostrar errores de validación -->
      <div v-if="errores.length" class="errores">
        <ul>
          <li v-for="(error, index) in errores" :key="index">
            {{ error }}
          </li>
        </ul>
      </div>

      <button class="button" type="submit">CONFIRMAR Y CREAR PACIENTE</button>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import AdminHeader from "./AdminComponents/AdminHeader.vue";

const paciente = ref({
  nombre: "",
  apellido: "",
  dni: "",
  fecha_nacimiento: "",
  madre: "",
  nro_tel_madre: "",
  padre: "",
  nro_tel_padre: "",
  telefono_caso_urgencia: "",
  obra_social: "",
  diagnostico: "",
  medico_neurologo: "",
  psiquiatra: "",
  pediatra: "",
  medicacion: "",
  alergico: "",
  ano_ingreso_vinculos: "",
  vencimiento_CUD:"",
  domicilio: { localidad: "Santa Fe" },
});

const obrasSociales = ref([]);
const errores = ref([]);

onMounted(() => {
  cargarObrasSociales();
});

const submitForm = async () => {
  errores.value = []; // Limpiar errores previos

  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/create-paciente`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(paciente.value),
    });

    const data = await response.json();

    if (!response.ok) {
      if (data.detalles && Array.isArray(data.detalles)) {
        // Convertir los errores en un array de mensajes entendibles
        errores.value = data.detalles.map((error) => error.message);
      } else {
        errores.value.push("Error desconocido al procesar la solicitud.");
      }
      return;
    }

    alert("Paciente agregado exitosamente");
    limpiarFormulario();
  } catch (error) {
    console.error("Error en la solicitud:", error);
    errores.value.push("No se pudo conectar con el servidor.");
  }
};

const limpiarFormulario = () => {
  paciente.value = {
    nombre: "",
    apellido: "",
    dni: "",
    fecha_nacimiento: "",
    madre: "",
    nro_tel_madre: "",
    padre: "",
    nro_tel_padre: "",
    telefono_caso_urgencia: "",
    obra_social: "",
    diagnostico: "",
    medico_neurologo: "",
    psiquiatra: "",
    pediatra: "",
    medicacion: "",
    alergico: "",
    domicilio: { localidad: "Santa Fe" },
    vencimiento_CUD: ""
  };
  errores.value = []; // Limpiar errores después de enviar correctamente
};

const agregarObraSocial = () => {
  console.log("Agregar obra social");
  window.open("/agregar-obras-sociales", "_blank");
};



const cargarObrasSociales = async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/os`);
    const data = await response.json();
    obrasSociales.value = data;
  } catch (error) {
    console.error("Error al cargar las obras sociales:", error);
  }
};



</script>

<style>
.errores {
  background: #ffdddd;
  color: #d8000c;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}
.errores ul {
  margin: 0;
  padding-left: 20px;
}

#venc_cud{
  color: var(--color-danger);
}



.custom-date-picker input[type="date"] {
  /* Estilos básicos */
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  font-size: 16px;
  color: #333;
  width: 200px;
}


</style>