import { defineStore } from "pinia";

export const useSessionStore = defineStore("session", {
  state: () => ({
    minutosRestantes: 0,
    intervalId: null,
    ultimaActualizacion: null,
  }),
  actions: {
    iniciarSesion(tiempoEnMinutos) {
      this.minutosRestantes = tiempoEnMinutos;
      this.ultimaActualizacion = Date.now();
      localStorage.setItem("ultimaActualizacion", this.ultimaActualizacion);
      this.iniciarContadorSesion();
    },

    iniciarContadorSesion() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      this.recalcularTiempoSesion(); // Ajustar el tiempo antes de iniciar el contador

      this.intervalId = setInterval(() => {
        if (this.minutosRestantes > 0) {
          this.minutosRestantes--;
          this.ultimaActualizacion = Date.now();
          localStorage.setItem("ultimaActualizacion", this.ultimaActualizacion);
        } else {
          clearInterval(this.intervalId);
          alert("La sesión ha expirado. Por favor, inicia sesión nuevamente.");
        }
      }, 60000); // Cada minuto
    },

    recalcularTiempoSesion() {
      const ultimaActualizacion = localStorage.getItem("ultimaActualizacion");
      if (ultimaActualizacion) {
        const tiempoPasado = Math.floor((Date.now() - parseInt(ultimaActualizacion)) / 60000);
        this.minutosRestantes = Math.max(0, this.minutosRestantes - tiempoPasado);
      }
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "sessionStore",
        storage: localStorage,
      },
    ],
  },
});
