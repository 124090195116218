<template>
  <div class="final-step-container">
    <h3>Confirmar Turno Recurrente</h3>
    <p>Pacientes seleccionados:</p>
    <ul>
      <li v-for="paciente in pacientes" :key="paciente.guid">
        {{ paciente.nombre }} {{ paciente.apellido }} (DNI: {{ paciente.dni || 'Sin DNI' }})
      </li>
    </ul>
    <p>Profesional: {{ profesional.nombre + " " + profesional.apellido }}</p>
    <p>Fecha: {{ fecha }}</p>
    <p>Horario: {{ horarioSeleccionado }}</p>

    <label for="consultorio">Consultorio:</label>
    <select id="consultorio" v-model="consultorioSeleccionado">
      <option v-for="consultorio in consultorios" :key="consultorio.id_consultorio" :value="consultorio.id_consultorio">
        {{ consultorio.nombre_consultorio }}
      </option>
    </select>

    <label for="observaciones">Observaciones:</label>
    <textarea id="observaciones" v-model="observaciones"></textarea>

    <div class="final-step-buttons">
      <button class="back-button" @click="cancelar">Cancelar</button>
      <button class="button" @click="confirmar">Confirmar</button>
    </div>
  </div>
</template>


<script setup>
import { ref, defineEmits, defineProps, onMounted } from 'vue';

const props = defineProps({
  pacientes: {
    type: Array, // Ahora es un array de pacientes
    required: true,
  },
  profesional: {
    type: Object,
    required: true,
  },
  fecha: {
    type: String,
    required: true,
  },
  horarioSeleccionado: {
    type: String,
    required: true,
  },
  dia_semana: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['confirmar', 'cancelar']);

// Estados del componente
const consultorioSeleccionado = ref('1');
const observaciones = ref('');
const consultorios = ref([]);


// Función para crear un turno recurrente individual o grupal
const crearTurnoRecurrente = async (ruta, datosTurnosRecurrentes) => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/turnos/${ruta}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datosTurnosRecurrentes),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || "Error al crear los turnos recurrentes");
    }

    return data;
  } catch (error) {
    console.error(`Error al crear los turnos recurrentes (${ruta}):`, error);
    throw error;
  }
};

// Método para confirmar los turnos recurrentes
const confirmar = async () => {
  const pacientesInfo = props.pacientes
    .map(paciente => `${paciente.nombre} ${paciente.apellido} (DNI: ${paciente.dni || 'Sin DNI'})`)
    .join('\n');

  const mensajeConfirmacion = `
    Confirmar Turno Recurrente
    Pacientes:
    ${pacientesInfo}
    Profesional: ${props.profesional.nombre} ${props.profesional.apellido}
    Fecha: ${props.fecha}
    Horario: ${props.horarioSeleccionado}
    Consultorio: ${consultorioSeleccionado.value}
    Observaciones: ${observaciones.value}

    ¡ATENCIÓN! ¿Estás seguro de que deseas confirmar estos turnos recurrentes?
  `;

  if (!window.confirm(mensajeConfirmacion)) {
    return;
  }

  try {
    const horaInicio = props.horarioSeleccionado.split(" - ")[0];
    const horaTurno = horaInicio;

    const datosTurnosRecurrentes = {
      profesional_guid: props.profesional.guid,
      id_consultorio: consultorioSeleccionado.value,
      observaciones: observaciones.value,
      dia_semana: props.dia_semana,
      hora: horaTurno,
      fecha: props.fecha,
    };

    let response;

    if (props.pacientes.length === 1) {
      // Turno para un solo paciente (individual)
      datosTurnosRecurrentes.paciente_guid = props.pacientes[0].guid;

      console.log("Enviando turno recurrente para 1 paciente:", datosTurnosRecurrentes);

      response = await crearTurnoRecurrente("create-turno-recurrente", datosTurnosRecurrentes);
    } else {
      // Turno para múltiples pacientes (grupal)
      datosTurnosRecurrentes.pacientes = props.pacientes.map(paciente => paciente.guid);

      console.log("Enviando turnos recurrentes para múltiples pacientes:", datosTurnosRecurrentes);

      response = await crearTurnoRecurrente("create-turno-recurrente-grupal", datosTurnosRecurrentes);
    }

    console.log("Turnos recurrentes creados exitosamente:", response);
    emit("confirmar", {});
    alert("Turnos recurrentes creados exitosamente.");
  } catch (error) {
    alert(error.message || "Error al confirmar los turnos recurrentes. Por favor, inténtelo de nuevo más tarde.");
  }
};


// Método para cancelar el turno
const cancelar = () => {
  emit('cancelar');
};

// Cargar consultorios activos
onMounted(() => {
  console.log("Pacientes recibidos en SetTurnoFinalStep:", props.pacientes);

  fetch(`${process.env.VUE_APP_API_URL}/turnos/get-consultorios-activos`)
    .then(response => response.json())
    .then(data => {
      consultorios.value = data;
      if (data.length > 0) {
        consultorioSeleccionado.value = data[0].id_consultorio;
      }
    })
    .catch(error => console.error('Error al recuperar los consultorios activos:', error));
});
</script>




  
  <style scoped>


  .final-step-container {
  background-color: #fff;
  padding: 20px; 
  border-radius: 2px;
  text-align: left;
    margin: 10px;
  box-sizing: border-box; 
  color: var(--color-h1);
  font-size: 1.2rem;
}


    h3{
        text-align: center;
        border-bottom: 1px solid var(--color-button);
    }

  label, select, textarea {
  display: block;
  margin-bottom: 10px;
  margin-top: 15px;
  margin-left: 10px;
}



textarea {
  width: calc(100% - 20px); /* Ajustar para considerar el padding del contenedor */
  height: 100px; /* Altura del campo de texto */
}

  .final-step-buttons{
    display: flex;
    justify-content: space-between;
   
  
  }

  p{
    margin: 5px 0px 5px 10px;

  }



  .horizontal-container-checkbox {
    font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: var(--color-danger);
}

.horizontal-container label {
  margin-right: 10px;
}

.horizontal-container input[type="checkbox"] {
  margin: 0; /* Eliminar cualquier margen para asegurar la alineación */
  vertical-align: middle; /* Alinear verticalmente al centro */
}

#show-alert{
    margin: 5px 10px 0px 10px;
}
  </style>