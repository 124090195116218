<template>
  <header class="header">
  
    <div class="left-section">
      <h1 class="title">{{ props.title }}</h1>
      <p :class="sessionTimeClass" class="session-time">
  Tiempo restante: {{ sessionStore.minutosRestantes }} minutos
</p>

    </div>
    <div class="right-section">
      <h2>{{ formattedDate }}</h2>
   
      <button class="button menu-button" @click="toggleDropdown">
        <span class="menu-icon">&#9776;</span>
      </button>
      <div :class="{ 'dropdown-menu': true, show: isDropdownOpen }">
        <div class="menu-section">
          <a href="/perfil" class="dropdown-item" @click="closeDropdown">Perfil</a>
          <a href="/logout" class="dropdown-item" @click="closeDropdown">Cerrar Sesión</a>
        </div>
        <div class="menu-divider"></div>
        <div class="menu-section">
          <a href="/admin" class="dropdown-item" @click="closeDropdown">Vista Principal</a>
          <a href="/turnos" class="dropdown-item" @click="closeDropdown">Turnos</a>
          <a href="/pacientes" class="dropdown-item" @click="closeDropdown">Pacientes</a>
          <a href="/profesionales" class="dropdown-item" @click="closeDropdown">Profesionales</a>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineProps, computed } from 'vue';
import { useSessionStore } from "@/stores/sessionStore";

const sessionStore = useSessionStore();

const props = defineProps({
  title: {
    type: String,
    default: 'ADMINISTRADOR',
  },
});

// Clases dinámicas para mostrar el tiempo restante en colores
const sessionTimeClass = computed(() => {
  if (sessionStore.minutosRestantes <= 5) return 'session-time danger'; // Rojo si quedan menos de 5 min
  if (sessionStore.minutosRestantes <= 15) return 'session-time warning'; // Naranja si quedan menos de 15 min
  return 'session-time safe'; // Verde si queda más de 15 min
});

const isDropdownOpen = ref(false);
const currentDate = ref(new Date());

// Formateo de la fecha en español
const formattedDate = computed(() => {
  const options = { weekday: 'long', day: 'numeric', month: 'long' };
  const dateString = currentDate.value.toLocaleDateString('es-ES', options);
  return dateString.replace(/(?:^|\s|["'([{¿¡])+\S/g, (char) => char.toUpperCase());
});

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const closeDropdown = () => {
  isDropdownOpen.value = false;
};

// Cerrar dropdown al hacer clic fuera
const handleClickOutside = (event) => {
  setTimeout(() => {
    if (!event.target.closest('.header')) {
      isDropdownOpen.value = false;
    }
  }, 100);
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);

  // Verificar si ya hay una sesión activa
  if (sessionStore.minutosRestantes > 0) {
    sessionStore.recalcularTiempoSesion(); // Ajustar el tiempo transcurrido
    sessionStore.iniciarContadorSesion(); // Iniciar el contador nuevamente
  }
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped>
.header {
  display: flex;
  align-items: center; /* Alinea verticalmente los elementos */
  justify-content: space-between; /* Espacio entre izquierda y derecha */
  padding: 10px 20px;
  background-color: var(--color-primary);
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.menu-button {
 
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem; /* Tamaño del ícono de hamburguesa */
  padding: 8px;
  border: 1px solid #333;
  color: black;
}

.menu-icon {
  display: block;
}

.left-section {
  display: flex;
  align-items: center;
  flex: 1; /* Ocupar el espacio disponible */
}

.right-section {
  position: relative;
  margin-left: auto; /* Alinea el div a la derecha */
  display: flex;     /* Habilita Flexbox en el contenedor */
  justify-content: center; /* Alinea los elementos horizontalmente al centro */
  align-items: center; /* Alinea los elementos verticalmente al centro */
}


.title {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}


/* Estilos para el menú desplegable */
.dropdown-menu {
  position: absolute;
  top: 100%; /* Posiciona el menú debajo del botón */
  right: 0; /* Alinea el menú a la derecha */
  background-color: white;
  border: 2px solid #333;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px; /* Ancho del menú */
  text-align: left;
  opacity: 0; /* Inicialmente invisible */
  visibility: hidden; /* Inicialmente oculto */
  transform: translateY(-10px); /* Desplazamiento inicial */
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
}

.dropdown-menu.show {
  opacity: 1; /* Visible */
  visibility: visible; /* Mostrar */
  transform: translateY(0); /* Sin desplazamiento */
}

.menu-section {
  padding: 8px 0;
}

.menu-divider {
  border-top: 1px solid #ddd;
  margin: 8px 0;
}

.dropdown-item {
  display: block;
  padding: 8px 16px;
  color: #333;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

/* Responsive Design */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .left-section,
  .right-section {
    width: 100%;
    flex-direction: column;
    align-items: flex-end; /* Alinea el botón a la derecha */
    gap: 10px;
  }

  .title {
    font-size: 1.2rem;
    text-align: center;
    width: 100%;
  }

  .dropdown-menu {
    position: absolute; /* Mantener posición absoluta */
    right: 0; /* Alineado a la derecha */
    width: 100%;
    box-shadow: none;
    border: none;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 8px;
  }

  .title {
    font-size: 1rem;
  }

  .dropdown-menu {
    position: absolute; /* Mantener posición absoluta */
    right: 0; /* Alineado a la derecha */
    width: 100%;
    box-shadow: none;
    border: none;
  }
}



.session-time {
  margin: 10px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1rem;
  display: inline-block;
}

.session-time.safe {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}

.session-time.warning {
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
}

.session-time.danger {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  animation: blink 1s infinite alternate;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}








</style>