<template>
  <div class="profesional-detalle-header-main-container" v-if="profesional">
    <div class="pre-header">
      <span>{{ profesional_guid }}</span>
    </div>
    <div class="header-section">
      <button class="button-secondary" @click="abrirModal">Editar datos profesional</button>
    </div>
  </div>
  <ModalEditarProfesional :mostrarModal="mostrarModal" :profesional="profesional" @close="cerrarModal" />
</template>

<script setup>
import { defineProps, ref } from 'vue';
import ModalEditarProfesional from '@/views/AdminViews/AdminComponents/ProfesionalesComponent/ModalEditarProfesional.vue';

const props = defineProps({
  profesional: {
    type: Object,
    required: false,
  },
});

console.log("profesional recibido de profesional detalles", props.profesional);
const mostrarModal = ref(false);
console.log(mostrarModal.value);
const abrirModal = () => {
  mostrarModal.value = true;
  console.log(mostrarModal.value);
};

const cerrarModal = () => {
  mostrarModal.value = false;
};
</script>

<style scoped>
.profesional-detalle-header-main-container {
  margin-top: 10px;
  padding: 15px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
}

.pre-header {
  display: flex;
  align-items: center;
}

.profesional-nombre {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}
</style>
