<template>
    <div class="home-container">
      <div class="logo-container">
        <!-- Aquí puedes reemplazar "icon.svg" con tu archivo de ícono -->
        <img src="@/assets/vinculos-logo.png" alt="Logo de VINCULOS" class="logo">
      </div>
    
  
      <router-link to="/login" class="login-link">Ir a Login</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomeView'
  }
  </script>
  

  <style scoped>
/* Estilo principal del contenedor */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Asegura que ocupe toda la altura de la pantalla */
  background-color: #f4f4f9; /* Fondo suave */
  font-family: 'Arial', sans-serif;
  text-align: center;
  padding: 20px;
}

/* Estilo del contenedor del logo */
.logo-container {
  margin-bottom: 20px;
}

/* Estilo de la imagen del logo */
.logo {
  width: 350px; /* Ajusta el tamaño del logo según tus necesidades */
  height: auto;
  border-radius: 10px; /* Agrega un borde redondeado al logo si es necesario */
 
}

/* Estilo del título principal */
h1 {
  font-size: 3rem;
  color: #333; /* Color oscuro para el texto principal */
  margin-bottom: 20px;
  letter-spacing: 2px; /* Espaciado entre letras para un aspecto más elegante */
  text-transform: uppercase; /* Texto en mayúsculas */
}

/* Estilo del enlace de login */
.login-link {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #fff; /* Texto blanco */
  background-color: #007bff; /* Azul primario */
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Efecto suave */
}

/* Hover para el enlace de login */
.login-link:hover {
  background-color: #0056b3; /* Azul más oscuro al pasar el mouse */
  transform: scale(1.05); /* Aumenta ligeramente el tamaño */
}

/* Estilo para dispositivos móviles */
@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem; /* Reduce el tamaño del título en pantallas pequeñas */
  }

  .login-link {
    font-size: 1rem; /* Reduce el tamaño del enlace en pantallas pequeñas */
  }
}
</style>
 
  