import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VCalendar from 'v-calendar'; // Importa V-Calendar
import 'v-calendar/style.css'; // Importa los estilos de V-Calendar
import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
const app = createApp(App);
// Crear una instancia de Pinia
const pinia = createPinia();
pinia.use(piniaPluginPersistedState);
// Usar Pinia en la aplicación
app.use(pinia);

// Usa Vue Router
app.use(router);

// Usa V-Calendar
app.use(VCalendar, {}); // Configura V-Calendar (puedes pasar opciones aquí si lo necesitas)

// Monta la aplicación
app.mount('#app');