<template>
    <AdminHeader />
    <div class="todos-los-turnos-container">
        <div>
    <div class="title-section-list">Todos los Turnos</div>
    <div class="search-container">
      <select v-model="selectedProfesional" class="search-select">
        <option value="" disabled selected>Seleccione un Profesional</option>
        <option v-for="profesional in profesionales" :key="profesional.guid" :value="profesional.guid">
          {{ profesional.nombre }} {{ profesional.apellido }}
        </option>
      </select>
      <button @click="filterByProfesional" class="button">Filtrar</button>
    </div>
  </div>
  <table>
  <thead>
    <tr>
      <th>Lunes</th>
      <th>Martes</th>
      <th>Miércoles</th>
      <th>Jueves</th>
      <th>Viernes</th>
      <th>Sábado</th>
      <th>Domingo</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(fila, index) in turnosOrganizados" :key="index">
      <td v-for="dia in ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']" :key="dia">
        <div v-if="fila[dia]" @click="mostrarModal(fila[dia])" style="cursor: pointer;">
          <div>{{ fila[dia].hora }} - {{ fila[dia].profesional.nombre }} {{ fila[dia].profesional.apellido }}</div>
          <div v-if="fila[dia].is_grupal">
            <span style="background-color: #4CAF50; color: white; padding: 2px 5px; border-radius: 3px;">GRUPAL</span>
            {{ fila[dia].pacientes.length }} pacientes
          </div>
          <div v-else-if="fila[dia].paciente">
            {{ fila[dia].paciente.nombre }} {{ fila[dia].paciente.apellido }}
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
  
      <div v-if="turnoSeleccionado" class="modal" @click.self="cerrarModal">
        <div class="modal-content">
  <span class="close" @click="cerrarModal">&times;</span>
  <h2>Detalles del Turno</h2>
  
  <div class="modal-section">
    <p><strong>Día:</strong> {{ turnoSeleccionado.dia_semana }}</p>
    <p><strong>Hora:</strong> {{ turnoSeleccionado.hora }}</p>
    <p><strong>Estado:</strong> {{ turnoSeleccionado.estado }}</p>
    <p><strong>Consultorio:</strong> {{ turnoSeleccionado.id_consultorio }}</p>
    <p v-if="turnoSeleccionado.observaciones"><strong>Observaciones:</strong> {{ turnoSeleccionado.observaciones }}</p>
  </div>

  <div class="modal-section">
    <h3>Profesional</h3>
    <p class="custom-link" @click="$router.push(`/profesional/${turnoSeleccionado.profesional.guid}`)">
      {{ turnoSeleccionado.profesional.nombre }} {{ turnoSeleccionado.profesional.apellido }}
    </p>
  </div>

  <div class="modal-section">
    <h3 v-if="turnoSeleccionado.is_grupal">Pacientes ({{ turnoSeleccionado.pacientes.length }})</h3>
    <h3 v-else>Paciente</h3>
    
    <div v-if="turnoSeleccionado.is_grupal" class="pacientes-list">
      <div v-for="(paciente, index) in turnoSeleccionado.pacientes" :key="index" class="paciente-item">
        <p class="custom-link" @click="$router.push(`/pacientes/${paciente.guid}`)">
          {{ paciente.nombre }} {{ paciente.apellido }} (DNI: {{ paciente.dni }})
        </p>
      </div>
    </div>
    <div v-else class="paciente-item">
      <p class="custom-link" @click="$router.push(`/pacientes/${turnoSeleccionado.paciente.guid}`)">
        {{ turnoSeleccionado.paciente.nombre }} {{ turnoSeleccionado.paciente.apellido }} (DNI: {{ turnoSeleccionado.paciente.dni }})
      </p>
    </div>
  </div>
</div>

      </div>
    </div>
  </template>
  <script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
import AdminHeader from "@/views/AdminViews/AdminComponents/AdminHeader.vue";

const turnosOrganizados = ref([]);
const turnoSeleccionado = ref(null);
const transformarTurnos = (turnos) => {
  const diasSemana = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  const turnosPorDia = {};

  turnos.forEach(turno => {
    if (!turnosPorDia[turno.dia_semana]) {
      turnosPorDia[turno.dia_semana] = [];
    }
    turnosPorDia[turno.dia_semana].push(turno);
  });

  // Ordenar los turnos por hora dentro de cada día
  diasSemana.forEach(dia => {
    if (turnosPorDia[dia]) {
      turnosPorDia[dia].sort((a, b) => {
        if (a.hora < b.hora) return -1;
        if (a.hora > b.hora) return 1;
        return 0;
      });
    }
  });

  const maxTurnos = Math.max(...Object.values(turnosPorDia).map(dia => dia.length));

  const filas = [];
  for (let i = 0; i < maxTurnos; i++) {
    const fila = {};
    diasSemana.forEach(dia => {
      fila[dia] = turnosPorDia[dia] ? turnosPorDia[dia][i] : null;
    });
    filas.push(fila);
  }

  return filas;
};


const mostrarModal = (turno) => {
  turnoSeleccionado.value = turno;
  document.addEventListener('keydown', cerrarConEsc); // Agregar listener de teclado aquí
};

const cerrarModal = () => {
  turnoSeleccionado.value = null;
  document.removeEventListener('keydown', cerrarConEsc); // Eliminar listener de teclado
};

const cerrarConEsc = (event) => {
  if (event.key === 'Escape') {
    cerrarModal();
  }
};


onMounted(async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/turnos/todos-los-turnos`);

    if (!response.ok) {
      throw new Error(`Error al obtener los turnos: ${response.status}`);
    }

    const data = await response.json();
    turnosOrganizados.value = transformarTurnos(data);
    console.log(turnosOrganizados.value)
  } catch (error) {
    console.error("Error al obtener los turnos:", error);
  }
});

onUnmounted(() => {
  document.removeEventListener('keydown', cerrarConEsc); // Asegurar que el listener se elimina al desmontar el componente
});


</script>


<style scoped>
/* Estilos para el modal */


.todos-los-turnos-container{
    padding: 20px;
}


.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 60%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>