<template>
  <div class="editar-profesional-modal-overlay" v-if="mostrarModal">
    <div class="modal-container">
      <div class="modal-header">
        <h2>Editar Profesional</h2>
        <button class="close-button" @click="closeModal">X</button>
      </div>

      <div class="form-container">
        <form @submit.prevent="guardarCambios" class="formulario">
          <label class="form-label">Nombre: <input v-model="profesionalEditable.nombre" type="text" required /></label>
          <label class="form-label">Apellido: <input v-model="profesionalEditable.apellido" type="text" required /></label>
          <label class="form-label">DNI: <input v-model="profesionalEditable.dni" type="text" required /></label>
          <label class="form-label">Teléfono: <input v-model="profesionalEditable.nro_telefono" type="text" required /></label>
          <label class="form-label">Email: <input v-model="profesionalEditable.email" type="email" required /></label>
          <label class="form-label">Fecha de Ingreso: <input v-model="profesionalEditable.fecha_ingreso" type="date" /></label>
          <label class="form-label">Especialidad: <input v-model="profesionalEditable.id_especialidad" type="number" required /></label>
          
          <div class="domicilio-fila">
            <label class="form-label">Localidad: <input v-model="profesionalEditable.domicilio.localidad" type="text" required /></label>
            <label class="form-label">Calle: <input v-model="profesionalEditable.domicilio.calle" type="text" /></label>
            <label class="form-label">Altura: <input v-model="profesionalEditable.domicilio.altura" type="number" /></label>
            <label class="form-label">Piso: <input v-model="profesionalEditable.domicilio.piso" type="text" /></label>
          </div>

          <label class="form-label">Observaciones: <input v-model="profesionalEditable.domicilio.observaciones" type="text" /></label>

          <!-- Mostrar errores de validación -->
          <div v-if="errores.length" class="errores">
            <ul>
              <li v-for="(error, index) in errores" :key="index">
                {{ error }}
              </li>
            </ul>
          </div>

          <div class="modal-footer">
            <button type="button" @click="closeModal" class="button">Cancelar</button>
            <button type="submit" class="button">Guardar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';

const props = defineProps({
  mostrarModal: Boolean,
  profesional: Object,
});

const emit = defineEmits(['close']);

const closeModal = () => {
  emit('close');
};


const extraerFecha = (fechaISO) => {
  if (!fechaISO) return '';
  return fechaISO.substring(0, 10);
};


const profesionalEditable = ref({
  guid: props.profesional?.guid,
  nombre: props.profesional?.nombre || '',
  apellido: props.profesional?.apellido || '',
  dni: props.profesional?.dni || '',
  nro_telefono: props.profesional?.nro_telefono || '',
  email: props.profesional?.email || '',
  fecha_ingreso: extraerFecha(props.profesional?.fecha_ingreso) || '',
  id_especialidad: props.profesional?.id_especialidad || '',
  domicilio: {
    localidad: props.profesional?.localidad || '',
    calle: props.profesional?.calle || '',
    altura: props.profesional?.altura || '',
    piso: props.profesional?.piso || '',
    observaciones: props.profesional?.observaciones || ''
  }
});

const errores = ref([]);

const guardarCambios = async () => {
  errores.value = []; // Limpiar errores previos

  if (!props.profesional?.guid) {
    alert("Error: Falta el GUID del profesional.");
    return;
  }

  // Hacer una copia de profesionalEditable.value
  const profesionalData = { ...profesionalEditable.value };

  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/actualizar-profesional/${props.profesional?.guid}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(profesionalData), // Enviar los datos del profesional
      credentials: 'include' // Esto ya envía automáticamente la cookie con el token

    });

    const data = await response.json();

    if (!response.ok) {
      if (data.detalles && Array.isArray(data.detalles)) {
        // Convertir los errores en un array de mensajes entendibles
        errores.value = data.detalles.map((error) => error.message);
      } else {
        errores.value.push("Error desconocido al procesar la solicitud.");
      }
      return;
    }

    alert("Profesional actualizado con éxito");
    closeModal();
  } catch (error) {
    console.error("Error en la actualización:", error);
    errores.value.push("No se pudo conectar con el servidor.");
  }
};

</script>

<style scoped>
.errores {
  background: #ffdddd;
  color: #d8000c;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}
.errores ul {
  margin: 0;
  padding-left: 20px;
}

.editar-profesional-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 90vw; /* Limita el ancho máximo al 90% del ancho de la ventana */
  max-height: 90vh; /* Limita la altura máxima al 90% de la altura de la ventana */
  overflow: auto; /* Permite el desplazamiento si el contenido excede el tamaño del contenedor */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.form-label {
  margin-bottom: 10px;
}

.button-secondary {
  margin-right: 10px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}
</style>
