<template>
  <div class="login-container">
    <h1>Vinculos | Iniciar Sesión</h1>
    
    <form @submit.prevent="login">
      <div class="input-group">
        <label for="username">Usuario</label>
        <input type="text" id="username" v-model="username" placeholder="Ingresa tu usuario" required>
      </div>

      <div class="input-group">
        <label for="password">Contraseña</label>
        <input type="password" id="password" v-model="password" placeholder="Ingresa tu contraseña" required>
      </div>

      <button type="submit" class="login-btn">Ingresar</button>
    </form>

    <router-link to="/" class="back-link">Volver a Inicio</router-link>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useSessionStore } from "@/stores/sessionStore";

const username = ref("");
const password = ref("");
const router = useRouter();
const sessionStore = useSessionStore();

const login = async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/auth-token/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: username.value,
        password: password.value,
      }),
      credentials: "include", // Para recibir la cookie
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Login exitoso", data);

      // Configurar minutos restantes (3 horas en minutos)
      sessionStore.iniciarSesion(180); // Esto inicia el contador automáticamente

      // Redirigir según el rol
      const userRole = data.user.role;
      if (userRole === 1) {
        router.push("/admin");
      } else if (userRole === 2) {
        router.push("/profesional-dashboard");
      } else {
        console.error("Rol no reconocido:", userRole);
      }
    } else {
      console.error("Error en el login");
    }
  } catch (error) {
    console.error("Error de red:", error);
  }
};
</script>






<style>
/* Estilos generales */
.login-container {
  max-width: 350px;
  margin: 50px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Estilos de los inputs */
.input-group {
  margin-bottom: 15px;
  text-align: left;
}

.input-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Botón de login */
.login-btn {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.login-btn:hover {
  background-color: #369d72;
}

/* Enlace de volver */
.back-link {
  display: block;
  margin-top: 15px;
  text-decoration: none;
  color: #42b983;
  font-size: 14px;
}

.back-link:hover {
  text-decoration: underline;
}

/* Responsive */
@media (max-width: 400px) {
  .login-container {
    width: 90%;
    padding: 15px;
  }

  .input-group input {
    font-size: 14px;
    padding: 8px;
  }

  .login-btn {
    font-size: 14px;
    padding: 8px;
  }
}
</style>
