<template>
  <div class="turnos-container" @click="cerrarMenus">
    <div class="header">
      <h2>Turnos del {{ formatoFecha(fechaSeleccionada) }}</h2>
    </div>

    <button @click="mostrarSelector = !mostrarSelector" class="date-toggle">
      {{ mostrarSelector ? 'Ocultar selector' : 'Cambiar fecha' }}
    </button>

    <div v-if="mostrarSelector" class="date-picker">
      <input 
        type="date" 
        v-model="fechaSeleccionada" 
        @change="fetchTurnos" 
        class="date-input" 
      />
    </div>

    <div class="turnos-list">
      <p class="selected-day"> {{ obtenerDiaSemana(fechaSeleccionada) }}</p> 
      <div v-if="isLoading" class="loading">Cargando turnos...</div>
      <div v-else-if="error" class="error">{{ error }}</div>
      <div v-else-if="turnos.length === 0" class="empty">No hay turnos para esta fecha</div>
      
      <div v-else>
        <div 
          v-for="turno in turnosOrdenados" 
          :key="turno.id_recurrente" 
          class="turno-card"
          :class="{ 
            'grupal': esGrupal(turno),
            'active': turnoActivo === turno.id_recurrente
          }"
          @mouseenter="hoverTurno = turno.id_recurrente"
          @mouseleave="hoverTurno = null"
          @click.stop="toggleTurnoActivo(turno)"
        >
          <div class="turno-time">{{ formatoHora(turno.fecha_turno) }}</div>
          <div class="turno-info">
            <span v-if="esGrupal(turno)" class="grupal-badge">Grupal</span>
            <div class="paciente-info">
              <div v-if="turno.pacientes && turno.pacientes.length > 0">
                <div v-for="(paciente, index) in turno.pacientes" :key="index">
                  {{ paciente.nombre_paciente }} {{ paciente.apellido_paciente }}
                </div>
              </div>
              <div v-else-if="!turno.nombre_paciente">Sin paciente asignado</div>
              <div v-else>{{ turno.nombre_paciente }} {{ turno.apellido_paciente }}</div>
            </div>
            
            <!-- Menú desplegable -->
            <div v-if="turnoActivo === turno.id_recurrente" class="acciones-turno" @click.stop>
  <button @click="verDetalles(turno)">Ver detalles</button>
  <button @click="editarTurno(turno)">Editar</button> <!-- Cambiado para pasar el objeto completo -->
</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal de Detalles -->
<transition name="modal">
  <div v-if="modalVisible" class="modal-mask" @click.self="cerrarModal">
    <div class="modal-container">
      <div class="modal-header">
        <h3>Detalles del Turno</h3>
        <button class="modal-close" @click="cerrarModal">&times;</button>
      </div>
      
      <div class="modal-body">
        <div class="detalle-item">
          <span class="detalle-label">Fecha:</span>
          <span>{{ formatoFechaHora(turnoSeleccionado.fecha_turno) }}</span>
        </div>
        
        <div class="detalle-item">
          <span class="detalle-label">Profesional:</span>
          <span>{{ turnoSeleccionado.nombre_profesional }} {{ turnoSeleccionado.apellido_profesional }}</span>
        </div>
        
        <div class="detalle-item">
          <span class="detalle-label">Especialidad:</span>
          <span>{{ turnoSeleccionado.especialidad }}</span>
        </div>
        
        <div class="detalle-item">
          <span class="detalle-label">Estado:</span>
          <span class="estado-badge" :class="turnoSeleccionado.estado.toLowerCase()">
            {{ turnoSeleccionado.estado }}
          </span>
        </div>
        
        <div class="detalle-item">
          <span class="detalle-label">Consultorio:</span>
          <span>{{ turnoSeleccionado.id_consultorio }}</span>
        </div>
        
        <div v-if="turnoSeleccionado.observaciones" class="detalle-item">
          <span class="detalle-label">Observaciones:</span>
          <span>{{ turnoSeleccionado.observaciones }}</span>
        </div>
        
        <!-- Sección de Pacientes -->
        <div class="pacientes-section">
          <h4 v-if="esGrupal(turnoSeleccionado)">Pacientes (Turno Grupal)</h4>
          <h4 v-else>Paciente</h4>
          
          <div v-if="turnoSeleccionado.pacientes && turnoSeleccionado.pacientes.length > 0">
            <div v-for="(paciente, index) in turnoSeleccionado.pacientes" :key="index" class="paciente-item">
              <span>{{ paciente.nombre_paciente }} {{ paciente.apellido_paciente }}</span>
             
            </div>
          </div>
          <div v-else class="paciente-item">
            Sin paciente asignado
          </div>
        </div>
      </div>
      
      <div class="modal-footer">
        <button @click="cerrarModal" class="back-button">Cerrar</button>
       
      </div>
    </div>
  </div>
</transition>

  <div> {{ disponibilidad }} </div>

  <ModalEditarTurno
    v-model:visible="modalEditarVisible"
    :turno="turnoAEditar || {}"
    :guid_profesional="props.guid_profesional"
    @guardar="actualizarTurno"
     @eliminar="eliminarTurno"
  />




  </div>
</template>

<script setup>
import { ref, defineProps, watch, computed, onMounted, onUnmounted } from 'vue';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import ModalEditarTurno from '@/views/AdminViews/AdminComponents/ProfesionalesComponent/SubComponentes/ModalEditarTurno.vue';

const props = defineProps({
  guid_profesional: {
    type: [String, Number],
    required: true,
  },
});

const fechaSeleccionada = ref(new Date().toISOString().substr(0, 10));
const turnos = ref([]);
const isLoading = ref(false);
const error = ref(null);
const mostrarSelector = ref(false);

const hoverTurno = ref(null);
const turnoActivo = ref(null);
const modalVisible = ref(false);
const turnoSeleccionado = ref(null);

// Estados para el modal (simplificado)
const modalEditarVisible = ref(false);
const turnoAEditar = ref(null);

// Función para cerrar menús al hacer clic fuera
const cerrarMenus = (event) => {
  if (!event.target.closest('.turno-card')) {
    turnoActivo.value = null;
  }
};



// Función simplificada para editarTurno
const editarTurno = (turno) => {
  turnoAEditar.value = turno ? { ...turno } : null;
  modalEditarVisible.value = true;
};

// Función para manejar la actualización (se mantiene igual)
const actualizarTurno = async (turnoActualizado) => {
  try {
    if (!turnoActualizado.id_recurrente) {
      throw new Error('ID de turno recurrente no proporcionado');
    }

    const datosActualizacion = {
      nueva_hora: turnoActualizado.nuevo_horario || null,
      observaciones: turnoActualizado.observaciones || null,
      estado: turnoActualizado.estado || 'PENDIENTE',
      nuevo_dia: turnoActualizado.dia_semana
    };

    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/turnos/actualizar-turno/${turnoActualizado.id_recurrente}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datosActualizacion),
        credentials: 'include'
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error al actualizar el turno');
    }

    const turnoActualizadoAPI = await response.json();
    const index = turnos.value.findIndex(t => t.id_recurrente === turnoActualizado.id_recurrente);
    if (index !== -1) {
      turnos.value[index] = { ...turnos.value[index], ...turnoActualizadoAPI };
    }
    
    modalEditarVisible.value = false;
    alert('Turno actualizado correctamente');
    await fetchTurnos();
    
  } catch (error) {
    console.error('Error al actualizar turno:', error);
    alert(error.message || 'Error al actualizar el turno');
  }
};


const eliminarTurno = async (idTurno) => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/turnos/${idTurno}`, {
      method: 'DELETE'
    });

    if (!response.ok) {
      throw new Error('Error al eliminar el turno');
    }

    // Actualiza la lista de turnos después de eliminar
    await fetchTurnos();
    modalEditarVisible.value = false;
  } catch (error) {
    console.error('Error al eliminar turno:', error);
    // Puedes mostrar un mensaje de error al usuario aquí
  }
};


// Agregar y remover el evento global de clic
onMounted(() => {
  document.addEventListener('click', cerrarMenus);
});

onUnmounted(() => {
  document.removeEventListener('click', cerrarMenus);
});

const toggleTurnoActivo = (turno) => {
  turnoActivo.value = turnoActivo.value === turno.id_recurrente ? null : turno.id_recurrente;
};

const verDetalles = (turno) => {
  turnoSeleccionado.value = turno;
  modalVisible.value = true;
  cerrarMenu();
};

const cerrarMenu = () => {
  turnoActivo.value = null;
};

const cerrarModal = () => {
  modalVisible.value = false;
  turnoSeleccionado.value = null;
};

const formatoFechaHora = (fecha) => {
  if (!fecha) return '';
  return format(parseISO(fecha), "EEEE d 'de' MMMM 'de' yyyy 'a las' HH:mm", { locale: es });
};

// Computed para ordenar turnos por hora
const turnosOrdenados = computed(() => {
  return [...turnos.value].sort((a, b) => {
    return new Date(a.fecha_turno) - new Date(b.fecha_turno);
  });
});

const fetchTurnos = async () => {
  isLoading.value = true;
  error.value = null;
  try {
    const response = await fetch(
      `${process.env.VUE_APP_API_URL}/turnos/get-turnos-dia-profesional/${fechaSeleccionada.value}/${props.guid_profesional}`
    );

    if (!response.ok) {
      throw new Error(`Error al obtener turnos: ${response.status}`);
    }
    const data = await response.json();
    const turnosFiltrados = data.filter((turno, index, self) => 
      index === self.findIndex((t) => t.id_recurrente === turno.id_recurrente)
    );

    turnos.value = turnosFiltrados;
  } catch (err) {
    error.value = err.message;
    console.error('Error al obtener los turnos:', err);
  } finally {
    isLoading.value = false;
  }
};

const formatoFecha = (fecha) => {
  if (!fecha) return '';
  return format(parseISO(fecha), "d 'de' MMMM 'de' yyyy", { locale: es });
};

const formatoHora = (fecha) => {
  if (!fecha) return '';
  return format(parseISO(fecha), 'HH:mm', { locale: es });
};

const esGrupal = (turno) => {
  return (
    (turno.pacientes && turno.pacientes.length > 1) || 
    turno.observaciones?.toUpperCase().includes('GRUPAL')
  );
};

const obtenerDiaSemana = (fecha) => {
  if (!fecha) return '';
  return format(parseISO(fecha), 'EEEE', { locale: es }).toUpperCase();
};


// Watchers
watch(() => props.guid_profesional, fetchTurnos);

watch(fechaSeleccionada, fetchTurnos);

// Initial data load
fetchTurnos();
</script>


  


<style scoped>
.turnos-container {
  max-width: 500px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

  .date-toggle{
    margin: 10px;
  }

  .selected-day {
  margin-top: 5px;
  font-size: 1.2rem;
  font-weight: bold;
 color: var(--color-violet);
}
 


.date-input {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.turnos-list {
  border-top: 1px solid #eee;
}

.turno-card {
  display: flex;
  padding: 1rem;
  border-left: 2px solid var(--color-violet);
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.turno-card:hover {
  background-color: #f5f5f5;
}

.turno-card.active {
  background-color: #f0f0f0;
  margin-bottom: 3.5rem; /* Espacio para el menú desplegable */
}

.acciones-turno {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #eee;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 10;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.acciones-turno button {
  padding: 0.5rem;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  border-radius: 3px;
}

.acciones-turno button:hover {
  background-color: #f5f5f5;
  color: var(--color-violet);
}

.paciente-info {
  flex: 1;
}



.turno-time {
  font-weight: bold;
  width: 60px;
  color: #555;
}

.turno-info {
  flex: 1;
}

.grupal-badge {
  background-color: var(--color-button);
  color: white;
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
  border-radius: 10px;
  margin-right: 0.5rem;
  display: inline-block;
}



.loading, .error, .empty {
  padding: 1rem;
  text-align: center;
  color: #666;
}

.error {
  color: #d32f2f;
}









/* Estilos del Modal */
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  padding: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h3 {
  margin: 0;
  color: var(--color-violet);
}

.modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.modal-body {
  margin-bottom: 20px;
}

.detalle-item {
  display: flex;
  margin-bottom: 12px;
  line-height: 1.5;
}

.detalle-label {
  font-weight: bold;
  min-width: 120px;
  color: #555;
}

.pacientes-section {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.pacientes-section h4 {
  margin-bottom: 10px;
  color: var(--color-violet);
}

.paciente-item {
  padding: 8px 0;
  border-bottom: 1px solid #f5f5f5;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.modal-btn {
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background: #f5f5f5;
  cursor: pointer;
}

.modal-btn.primary {
  background-color: var(--color-violet);
  color: white;
  border-color: var(--color-violet);
}

.estado-badge {
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 0.85rem;
  font-weight: 500;
}

.estado-badge.pendiente {
  background-color: #fff3cd;
  color: #856404;
}

.estado-badge.confirmado {
  background-color: #d4edda;
  color: #155724;
}

.estado-badge.cancelado {
  background-color: #f8d7da;
  color: #721c24;
}

/* Transiciones */
.modal-enter-from, .modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  transform: scale(0.95);
}
</style>