<template>
  <AdminHeader />
  <div class="form-container profesional-add-container">
    <h2>Agregar Profesional</h2>
    <form @submit.prevent="submitForm" class="formulario">
      <label class="form-label">Nombre:
        <input v-model="profesional.nombre" type="text" />
      </label>
      <label class="form-label">Apellido:
        <input v-model="profesional.apellido" type="text" />
      </label>
      <label class="form-label">DNI:
        <input v-model="profesional.dni" type="text" />
      </label>
      <label class="form-label">Especialidad:
        <select v-model="profesional.id_especialidad">
          <option v-for="especialidad in especialidades" :key="especialidad.id" :value="especialidad.id">
            {{ especialidad.nombre }}
          </option>
        </select>
      </label>
      <div>
        <button type="button" @click="toggleAgregarEspecialidad">{{ botonAgregarEspecialidadTexto }}</button>

      </div>
      <AgregarEspecialidad v-if="mostrarAgregarEspecialidad" @especialidadAgregada="onEspecialidadAgregada" @cancelar="mostrarAgregarEspecialidad = false" />
      <label class="form-label">Teléfono:
        <input v-model="profesional.nro_telefono" type="text" />
      </label>
      <label class="form-label">Correo Electrónico:
        <input v-model="profesional.email" type="email" />
      </label>

      <!-- Domicilio -->
      <div class="domicilio-fila">
        <label class="form-label">Localidad:
          <input v-model="profesional.domicilio.localidad" type="text" />
        </label>
        <label class="form-label">Calle:
          <input v-model="profesional.domicilio.calle" type="text" />
        </label>
        <label class="form-label">Altura:
          <input v-model="profesional.domicilio.altura" type="number" />
        </label>
        <label class="form-label">Piso:
          <input v-model="profesional.domicilio.piso" type="text" />
        </label>
        <label class="form-label">Observaciones:
          <input v-model="profesional.domicilio.observaciones" type="text" />
        </label>
      </div>

      <!-- Mostrar errores de validación -->
      <div v-if="errores.length" class="errores">
        <ul>
          <li v-for="(error, index) in errores" :key="index">
            {{ error }}
          </li>
        </ul>
      </div>

      <button class="button" type="submit">CONFIRMAR Y CREAR PROFESIONAL</button>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import AdminHeader from "@/views/AdminViews/AdminComponents/AdminHeader.vue";
import AgregarEspecialidad from '@/views/AdminViews/AdminComponents/ProfesionalesComponent/AgregarEspecialidad.vue';

const profesional = ref({
  nombre: "",
  apellido: "",
  dni: "",
  nro_telefono: "",
  email: "",
  domicilio: {
    localidad: "",
    calle: "",
    altura: null,
    piso: "",
    observaciones: "",
  },
  id_especialidad: null,
});

const especialidades = ref([]);
const errores = ref([]);
const mostrarAgregarEspecialidad = ref(false);
const botonAgregarEspecialidadTexto = ref("Agregar especialidad");

onMounted(() => {
  cargarEspecialidades();
});



const submitForm = async () => {
  errores.value = []; // Limpiar errores previos

  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/create-profesional`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Configurar tipo de contenido
      },
      body: JSON.stringify(profesional.value),
      credentials: 'include', // Incluye automáticamente cookies con el token
    });

    const data = await response.json();

    if (!response.ok) {
      if (data.error) {
        errores.value.push(data.error); // Maneja errores del backend
      } else {
        errores.value.push("Error al crear profesional. Por favor, inténtelo de nuevo.");
      }
      return;
    }

    alert("Profesional creado exitosamente."); // Notificación de éxito
    limpiarFormulario(); // Limpia los datos del formulario
  } catch (error) {
    console.error("Error en la solicitud:", error); // Manejo de errores en el cliente
    errores.value.push("No se pudo conectar con el servidor.");
  }
};



const cargarEspecialidades = async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/profesionales/especialidades`);
    const data = await response.json();
    especialidades.value = data;
  } catch (error) {
    console.error("Error al cargar especialidades:", error);
  }
};

const limpiarFormulario = () => {
  profesional.value = {
    nombre: "",
    apellido: "",
    dni: "",
    nro_telefono: "",
    email: "",
    domicilio: {
      localidad: "",
      calle: "",
      altura: null,
      piso: "",
      observaciones: "",
    },
    id_especialidad: null,
  };
  errores.value = [];
};

const toggleAgregarEspecialidad = () => {
  mostrarAgregarEspecialidad.value = !mostrarAgregarEspecialidad.value;
  if (mostrarAgregarEspecialidad.value) {
    botonAgregarEspecialidadTexto.value = "Listo";
  } else {
    botonAgregarEspecialidadTexto.value = "Agregar especialidad";
    cargarEspecialidades();
  }
};
</script>

<style>
.errores {
  background: #ffdddd;
  color: #d8000c;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}
.errores ul {
  margin: 0;
  padding-left: 20px;
}
.domicilio-fila {
  display: flex;
  flex-wrap: wrap;
}

.domicilio-fila label {
  width: 20%; /* Ajusta según sea necesario */
  margin-right: 10px;
}
</style>