<template>
  <div class="modal-container" v-if="isModalOpen">

      <div class="modal-overlay" @click="closeModal"></div>

      <div class="modal-content">

         

          <!-- Paso 1: Seleccionar Paciente -->
          <SelectPaciente 
  v-if="currentStep === 1" 
  :pacientesSeleccionados="selectedPacientes" 
  @next="handlePacientesSeleccionados" 
/>


          <!-- Paso 2: Seleccionar Profesional -->
          <SelectProfesional  
  v-if="currentStep === 2" 
  :pacientesSeleccionados="selectedPacientes" 
  @next="handleProfesionalSeleccionado" 
  @back="goToPreviousStep" 
/>

          
          <!-- Paso 3: Seleccionar Fecha -->
          <SelectFecha  
  v-if="currentStep === 3" 
  :pacientesSeleccionados="selectedPacientes" 
  :profesional="selectedProfesional" 
  @finish="handleFechaSeleccionada"  
  @back="goToPreviousStep" 
/>

      </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { defineProps, defineEmits } from 'vue';
import SelectPaciente from '@/views/AdminViews/AdminComponents/TurnosAddComponent/SelectPaciente.vue';
import SelectProfesional from '@/views/AdminViews/AdminComponents/TurnosAddComponent/SelectProfesional.vue';
import SelectFecha from '@/views/AdminViews/AdminComponents/TurnosAddComponent/SelectFecha.vue';

// Props recibidas del padre
const props = defineProps({
    isModalOpen: {
        type: Boolean,
        required: true,
    },
});

// Emits para comunicarse con el padre
const emit = defineEmits(['close']);

// Estado reactivo interno
const currentStep = ref(1);
const selectedPacientes = ref([]); // Almacenar múltiples pacientes seleccionados
const selectedProfesional = ref(null);

// Métodos
const handlePacientesSeleccionados = (pacientes) => {
    if (!pacientes || pacientes.length === 0) {
        console.warn("No hay pacientes seleccionados.");
        return;
    }
    selectedPacientes.value = pacientes; // Actualizar la lista de pacientes seleccionados
    console.log("Pacientes seleccionados en el padre:", selectedPacientes.value); // Verificar datos
    goToNextStep();
};

const handleProfesionalSeleccionado = (profesional) => {
    if (!profesional) return;
    selectedProfesional.value = profesional;
    goToNextStep();
};

const handleFechaSeleccionada = async (data) => {
    const fechaSeleccionada = data.fecha;
    const profesionalSeleccionado = data.profesional;

    const profesionalGuid = profesionalSeleccionado.guid;

    try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/turnos/horarios-disponibles/${profesionalGuid}/${fechaSeleccionada}`);
        if (!response.ok) {
            throw new Error('Error al obtener los turnos');
        }
    } catch (error) {
        console.error("Error al obtener los turnos:", error);
    }

    closeModal();
};

const closeModal = () => {
    emit('close');
};

const goToNextStep = () => {
    currentStep.value++;
};

const goToPreviousStep = () => {
    currentStep.value--;
};



// Observador para reiniciar el paso cuando el modal se cierra
watch(
    () => props.isModalOpen,
    (newVal) => {
        if (!newVal) {
            currentStep.value = 1; // Reinicia al primer paso cuando se cierra el modal
            selectedPacientes.value = []; // Reiniciar selección de pacientes
            selectedProfesional.value = null;
        }
    }
);




</script>
  
  <style scoped>

  /* Estilos generales */
  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow-y: auto;
   
  }
  
  .modal-overlay {
  
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semitransparente */
  z-index: 1000; /* Asegura que la superposición esté por debajo del modal */
  }
  
.modal-content {
  position: relative;
  background-color: #fff;
  width: 80%; /* Ancho del modal */
  max-width: 90%;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
  max-height: 90vh; /* Altura máxima del contenido del modal (90% de la pantalla) */
  overflow-y: auto; /* Scroll vertical para el contenido del modal si es necesario */
  padding: 20px;
  border-radius: 2px;
 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  /* Animación de entrada */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  </style>