<template>
    <div class="paciente-detalle-header-main-container" v-if="paciente">
      <div class="pre-header">
        <span>{{ paciente_guid }}</span>
      </div>
      <div class="header-section">
        <button class="button-secondary" @click="abrirModal">Editar datos paciente</button>
      </div>
    </div>
    <ModalEditarPaciente :mostrarModal="mostrarModal" :paciente="paciente" @close="cerrarModal" />



  </template>
  
  <script setup>
  import { defineProps, ref } from 'vue';
  import ModalEditarPaciente from '@/views/AdminViews/AdminComponents/PacientesComponent/ModalEditarPaciente.vue';
  
  const props = defineProps({
  paciente: {
    type: Object,
    required: false,
  },
});

  console.log("paciente recibido de paciente detalles",props.paciente)
  const mostrarModal = ref(false);
  console.log(mostrarModal.value)
  const abrirModal = () => {
    mostrarModal.value = true;
    console.log(mostrarModal.value)
  };
  
  const cerrarModal = () => {
    mostrarModal.value = false;
  };
  </script>
  
  <style scoped>
  .paciente-detalle-header-main-container {
    margin-top: 10px;
    padding: 15px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
  }
  
  .pre-header {
    display: flex;
    align-items: center;
  }
  
  .paciente-nombre {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }
  </style>