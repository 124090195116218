<template>
    <div class="form-container">
    <h3>Agregar Especialidad</h3>
    <form @submit.prevent="submitForm">
      <div class="form-row">
        <label for="nombre">Nombre de la Especialidad:</label>
        <input v-model="nombre" type="text" id="nombre" required />
        <button id="boton-agregar-especialidad" type="submit">Agregar</button>
      </div>
    </form>
    <div v-if="message">{{ message }}</div>
  </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  
  const nombre = ref('');
  const message = ref('');
  
  const submitForm = async () => {
    if (!nombre.value.trim()) {
      message.value = 'El nombre de la especialidad es obligatorio';
      return;
    }
  
    try {
      const response = await fetch(`${process.env.VUE_APP_API_URL}/adm/profesionales/create-especialidad`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ nombre: nombre.value })
      });
  
      if (response.ok) {
        const data = await response.json();
        message.value = data.message;
        nombre.value = '';
      } else {
        const errorData = await response.json();
        message.value = errorData.error;
      }
    } catch (error) {
      console.error('Error al crear la especialidad:', error);
      message.value = 'Error al crear la especialidad';
    }
  };
  </script>
  
  <style scoped>
.form-container{
    
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 10px;
    }

    .form-row {
      display: flex;
      align-items: center;
      gap: 10px; /* Espacio de 10px entre los elementos de la fila */
    }

    #boton-agregar-especialidad {
      margin-left: auto; /* Para alinear el botón a la derecha */
    }
  </style>
  