<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>







<style>
@import './assets/styles/tablas.css';
@import './assets/styles/search-stiles.css';
@import './assets/styles/formularios.css';

@import '~@fortawesome/fontawesome-free/css/all.css';



:root {
  --color-primary: #ffffff; /* Azul */
  --color-secondary: #6c757d; /* Gris */
  --color-success: #28a745; /* Verde */
  --color-danger: #F14726; /* Rojo */
  --color-light: #F2F2F2;
  --color-violet: #424BB2;
  --color-violet-rgb: 66, 75, 178;  /* equivalente RGB para usar con rgba() */
  --color-h1: #383838;   /* cuiado con cambiar el nombre, es h1, pero no representa el color de los elementos h1 */
  --color-button: #424BB2; 
  --color-button-text: #222;

  --color-button-secondary: #6A73C3;
  --color-button-secondary-text: white;

}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

#app {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  color: #2c504a;
  margin-top: 10px;

  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Tipografía moderna */
}



 
  h1{   
    text-align: center;
    color: #F14726;  
  }


  h2{   
    color: var(--color-h1); 
    /* margin: 10px 10px 0px 0px; */
  }
  
  

  .txt-title-section {
  font-size: 48px;
  margin-bottom: 10px;
  text-align: left;
  color: var(--color-h1); 
}

/* TITULO PRINCIPAL DE LAS PAGINAS COMO: PacientesPageADM.vue  donde esta la lista de pacientes  */

.title-section-list {
  text-align: center;
 
  font-size: 2.5rem; /* Tamaño de fuente grande */

  padding: 10px 20px; /* Espaciado interno */

  text-transform: uppercase; /* Texto en mayúsculas */
  
  letter-spacing: 2px; /* Espaciado entre letras */
  
}



.link {
    margin: 0px;
    text-decoration: none;
    color: var(--color-violet);
    cursor: pointer
  }

  .link:hover {
    
    color: #000000;
  }


 button{
  margin-left: 10px;
    padding: 8px 16px;
    background-color: var(--color-button);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
 }


.button-secondary {
  background-color: var(--color-button-secondary);
  color: var(--color-button-secondary-text);
  padding: 6px 12px; /* Reducción del padding para hacer el botón más pequeño */
  font-size: 0.875rem; /* Tamaño de fuente más pequeño */
  border-radius: 2px;
}

  .back-button {
    background-color: #ccc;
    color: #333;
    margin-left: 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 2px;
    cursor: pointer;


  }
  


  .button-container-modal-steps {
    margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

 

select {
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 2px;
  font-size: 1rem;
}





/* ESTILOS DE PECIENTES -> PROFESIONAL -> FECHA : PARA CONFORMAR TURNO COMPONENTES /ADMINCOMPONENTES/TURNOSADDCOMPONENT */
.recordatorio-selected-paciente, .recordatorio-selected-profesional {
  font-size: 16px;
  color: #333;
  margin: 10px 0;
  background-color: transparent; /* Eliminar recuadro de color */
  border: none; /* Eliminar bordes */
  border-bottom: 1px solid #ddd; /* Agregar una línea divisoria en la parte inferior */
  border-radius: 0; /* Eliminar el border-radius ya que no es necesario sin un recuadro */
  text-align: left; /* Alinear el texto a la izquierda */
}

.select-paciente li.selected {
  background-color: #42b983; /* Color de fondo para el seleccionado */
  color: white; /* Cambia el color del texto si lo necesitas */
  font-weight: bold; /* Opcional: hace que el texto resalte */
}








.tabla-container {
  width: 100%;
  height: 500px; /* Ajusta la altura según sea necesario */
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.custom-link {
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
}

.custom-link:hover {
  color: #2980b9;
  text-decoration: underline;
}


.recurrente {
  color: #4caf50; /* Verde para turnos recurrentes */
  font-weight: bold;
}

.normal {
  color: #3498db; /* Azul para turnos normales */
  font-weight: bold;
}







.separador {
    margin-top: 20px;
    height: 1px; /* Ajusta este valor según sea necesario */
  background-color: transparent; /* Puedes cambiar el color si quieres que sea visible */
  border-bottom: 2px solid #eee; /* Añadir borde solo en la parte superior */
  width: 100%; /* Asegurar que el ancho sea del 100% */
  box-sizing: border-box; /* Incluir el borde en el cálculo del ancho */
 
  }






  
</style>
